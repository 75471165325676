import React from "react";
import moment from "moment";

import { get, getNow } from "../services";

import styles from "./styles.module.scss";
import { isBodyOverflowing } from "reactstrap/lib/utils";
import { sources, site } from "components/sources";
import { TimeContext } from "contexts/TimeContext";

class Timer extends React.Component {
  static contextType = TimeContext;

  constructor(props) {
    super(props);
    this.state = {
      isOn: false,
      left: this.props.end_date - Date.now(),
      call_made: false,
      serverTime: Date.now(),
      initTime: Date.now(),
      end_date: Date.now() + 60000,
    };
    this.startTimer = this.startTimer.bind(this);
  }

  startTimer() {
    // fetch(`${sources[site].url}/api/time/`)
    //   .then((response) => response.json())
    //   .then((data) => console.log(data));

    this.setState({
      isOn: true,
      start: Date.now(),
    });
    this.timer = setInterval(() => {
      //
      //suma tiempo de servidor + (date.now - localTime)

      // const resta = this.context.date + (Date.now() - this.context.localTime);

      return this.setState({
        left:
          this.props.end_date -
          getNow(this.context.date, this.context.localTime),
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidUpdate() {
    // tengo el local time al momento de iniciar la app y el date now, tengo que saber hace cuanto que esta en la app
    // console.log("contexto", this.context);
    // console.log("resta", Date.now() - this.context.localTime);
    if (this.state.isOn && this.state.left <= 0 && !this.state.call_made) {
      this.setState({ call_made: true });
      this.props.callback(true);
    } else if (this.state.isOn && this.state.left > 0 && this.state.call_made) {
      this.setState({ call_made: false });
    }
  }

  componentDidMount() {
    this.setState({
      serverTime: this.context.date,
      initTime: this.context.date,
      localTime: this.context.localTime,
    });

    this.startTimer();
  }

  render() {
    //console.log("timer", this.props.view);
    var left = new moment.duration(this.state.left);
    var days = 0;
    var hours = 0;
    var minutes = 0;
    var seconds = 0;
    var showFinish = this.props.view;
    var sourceView =
      typeof this.props.sourceView !== "undefined" ? this.props.sourceView : "";

    if (this.state.left > 0) {
      days = Math.floor(left.asDays());
      hours = Math.floor(left.asHours());
      minutes = Math.floor(left.asMinutes());
      seconds = Math.floor(left.asSeconds()) - 60 * minutes;
    }
    minutes -= 60 * hours;
    hours -= 24 * days;
    seconds = ("0" + seconds).slice(-2);
    minutes = ("0" + minutes).slice(-2);
    hours = ("0" + hours).slice(-2);
    var timer = (
      <div className={"btn btn-sm btn-outline-danger"}>
        {days} Dias, {hours}:{minutes}:{seconds}
      </div>
    );
    var settings = get(this.props, "settings", false);
    var display_days = get(settings, "display_days", true) && days > 0;
    var display_hours = get(settings, "display_hours", true) && hours > 0;

    if (showFinish) {
      if (!display_days) {
        timer = (
          <div className={"btn btn-sm btn-outline-danger"}>
            <b>
              {hours}:{minutes}:{seconds}
            </b>
          </div>
        );
        if (!display_hours) {
          timer = (
            <div className={`${styles["parpadea"]} ${styles["timer"]}`}>
              <b>terminando</b>
            </div>
          );
        }
      }
    } else {
      timer = (
        <div
          className={`btn btn-outline-danger ${
            this.props.sourceView == "detail" ? "btn-lg" : "btn-sm"
          } `}
        >
          <b>
            {days} Dias, {hours}:{minutes}:{seconds}
          </b>
        </div>
      );
      if (!display_days) {
        timer = (
          <div
            className={`btn btn-outline-danger ${
              this.props.sourceView == "detail" ? "btn-lg" : "btn-sm"
            }`}
          >
            <b>
              {hours}:{minutes}:{seconds}
            </b>
          </div>
        );
        if (this.state.left > 0 && !display_hours) {
          timer = (
            <div
              className={`btn btn-outline-danger ${
                this.props.sourceView == "detail" ? "btn-lg" : "btn-sm"
              }`}
            >
              <b>
                {minutes}:{seconds}
              </b>
            </div>
          );
        }
      }
    }

    return timer;
  }
}

export default Timer;
