/***
 * component: Recomendaciones para martilleros a la hora de realizar transmisiones  en vivo.
 */

import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Collapse } from "reactstrap";

import styles from "./styles.module.scss";

import setBroadcastBtn from "assets/img/broadcasts/setBroadcastBtn.jpeg";
import setBroadcastForm from "assets/img/broadcasts/setBroadcastForm.jpeg";
import setOBSConf from "assets/img/broadcasts/setOBSConf.jpeg";
import YTStudioCreateBtn from "assets/img/broadcasts/YTStudioCreateBtn.jpeg";
import YTCellValidation1 from "assets/img/broadcasts/YTCellValidation1.jpeg";
import YTCellValidation2 from "assets/img/broadcasts/YTCellValidation2.jpeg";
import YTStudioFirstTime1 from "assets/img/broadcasts/YTStudioFirstTime1.jpeg";
import YTStudioFirstTime2 from "assets/img/broadcasts/YTStudioFirstTime2.jpeg";
import YTStudioWebcam1 from "assets/img/broadcasts/YTStudioWebcam1.jpeg";
import YTStudioWebcam2 from "assets/img/broadcasts/YTStudioWebcam2.jpeg";
import YTStudioWebcamPreview from "assets/img/broadcasts/YTStudioWebcamPreview.jpeg";
import YTStudioScheduleBtn from "assets/img/broadcasts/YTStudioScheduleBtn.jpeg";
import YTStudioNTMenu from "assets/img/broadcasts/YTStudioNTMenu.jpeg";
import YTStudioDashboard from "assets/img/broadcasts/YTStudioDashboard.jpeg";
import setOBSService from "assets/img/broadcasts/setOBSService.jpeg";
import setOBSKey from "assets/img/broadcasts/setOBSKey.jpeg";
import OBSStreamBtn from "assets/img/broadcasts/OBSStreamBtn.jpeg";
import YTStudioShareBtn1 from "assets/img/broadcasts/YTStudioShareBtn1.jpeg";
import YTStudioShareBtn2 from "assets/img/broadcasts/YTStudioShareBtn2.jpeg";
import { sources, site } from "components/sources";

const theme = `${sources[site].theme_name}`;
var background = `bg-${theme}`;

const RequisitosTransmision = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Card
      className={`${styles["tip-card"]} ${
        isOpen ? styles["tip-card--shadow"] : ""
      } `}
    >
      <CardHeader className={`${background} ${styles["tip-card__header"]}`}>
        <CardTitle className={`${styles["tip-card__header__title"]}`}>
          <h5 className={"text-white"}>Requisitos</h5>
        </CardTitle>
        <button
          className={[`btn btn-sm btn-primary`]}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? "Ocultar" : "Mostrar"}
        </button>
      </CardHeader>
      <Collapse className={"text-center"} isOpen={isOpen}>
        <CardBody className={`bg-secondary ${styles["tip-card__body"]}`}>
          <ol className={"text-justify"}>
            <li>
              Requisitos de Hardware
              <ol type={"a"}>
                <li>
                  <p>
                    <b>PC o Notebook</b>, no recomendamos usar un dispositivo
                    móvil para garantizar la capacidad de procesamiento y ancho
                    de banda necesarios.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Acceso a internet de banda ancha y estable</b>, se
                    recomienda usar una red cableada para el acceso a internet o
                    estar cerca del Router en caso de usar WiFi.
                  </p>
                  <p>
                    Además de asegurarse de que el servicio contratado ofrezca
                    un buen ancho de banda de subida de datos.
                  </p>
                  <p>
                    Para tener una buena referencia, se puede usar el sitio
                    <a
                      target={"blank"}
                      className={"btn btn-link btn-sm"}
                      href={"https://www.speedtest.net"}
                    >
                      {" "}
                      speedtest
                    </a>
                    para medir el ancho de banda y ver la estabilidad de la
                    señal (mientras menos se mueva la aguja del velocímetro, más
                    estable es la señal).
                  </p>
                </li>
                <li>
                  <p>
                    <b>Cámara y micrófono</b> conectados a la PC o Notebook.
                  </p>
                </li>
              </ol>
            </li>
            <li>Requisitos de Software</li>
            <ol type={"a"}>
              <li>
                <p>
                  <b>Navegador web Google Chrome</b> actualizado a la versión
                  más reciente. Puede conseguirlo{" "}
                  <a
                    target={"blank"}
                    className={"btn btn-link btn-sm"}
                    href={"https://www.google.com/intl/es-419/chrome/"}
                  >
                    Aquí
                  </a>
                  .
                </p>
              </li>
              <li>
                <p>
                  <b>Programa para broadcasting</b> (Opcional. Sólo si se quiere
                  acceder a opciones avanzadas como compartir pantalla/ventana,
                  añadir banners, cuenta regresiva, efectos de sonido, múltiples
                  cámaras, etc.).
                </p>
                <p>
                  Nosotros recomendamos usar{" "}
                  <a
                    target={"blank"}
                    className={"btn btn-link btn-sm"}
                    href={"https://obsproject.com/es"}
                  >
                    OBS
                  </a>{" "}
                  por ser gratuito y multiplataforma.
                </p>
              </li>
              <li>
                <p>
                  <b>Cuenta de gmail</b> (Opcional. Si no se quiere usar un
                  programa de Broadcasting, sólo permite transmitir desde una
                  cámara y un micrófono).
                </p>
              </li>
            </ol>
          </ol>
        </CardBody>
      </Collapse>
    </Card>
  );
};

const ModoTransmision = () => {
  const [wichIsOpen, setIsOpen] = useState({
    1: false,
    2: false,
  });

  const handleOpenTransmision = (modo) => {
    if (wichIsOpen[1] == false && wichIsOpen[2] == false) {
      setIsOpen({
        ...wichIsOpen,
        [modo]: true,
      });
    } else if (wichIsOpen[1]) {
      setIsOpen({
        1: false,
        2: true,
      });
    } else if (wichIsOpen[2]) {
      setIsOpen({
        1: true,
        2: false,
      });
    }
  };

  return (
    <>
      <ModoTransmision1
        handleOpenTransmision={handleOpenTransmision}
        isOpen={wichIsOpen[1]}
      ></ModoTransmision1>
      <ModoTransmision2
        handleOpenTransmision={handleOpenTransmision}
        isOpen={wichIsOpen[2]}
      ></ModoTransmision2>
    </>
  );
};

const ModoTransmision1 = ({ handleOpenTransmision, isOpen }) => {
  const [show_setBroadcastBtn, setshow_setBroadcastBtn] = useState(false);
  const [show_setBroadcastForm, setshow_setBroadcastForm] = useState(false);
  const [show_setOBSConf, setshow_setOBSConf] = useState(false);

  return (
    <Card className={`${styles["tip-card"]}`}>
      <CardHeader className={`${background} ${styles["tip-card__header"]}`}>
        <CardTitle className={`${styles["tip-card__header__title"]}`}>
          <h5 className={"text-white"}>Modo 1: Sin cuenta de YouTube</h5>
        </CardTitle>
        <button
          className={[`btn btn-sm btn-primary`]}
          onClick={() => {
            handleOpenTransmision(1, !isOpen);
          }}
        >
          {isOpen ? "Ocultar" : "Mostrar"}
        </button>
      </CardHeader>
      <Collapse className={"text-center"} isOpen={isOpen}>
        <CardBody className={`bg-secondary ${styles["tip-card__body"]}`}>
          {/*<h5 className={'text-center'}>Modo 1: Sin cuenta de YouTube</h5>*/}
          <p className={"text-left"}>
            <b>Ventajas:</b>
          </p>
          <ul className={"text-justify"}>
            <li>
              <p>
                No tiene que lidiar con la configuración de la transmisión en
                YouTube Studio.
              </p>
            </li>
            <li>
              <p>
                Puede dedicar más tiempo a la calidad técnica de la transmisión.
              </p>
            </li>
          </ul>
          <p className={"text-left"}>
            <b>Desventajas:</b>
          </p>
          <ul className={"text-justify"}>
            <li>
              <p>
                Debe instalar y usar un programa de Broadcasting aunque no
                aproveche las ventajas que este ofrece.
              </p>
            </li>
            <li>
              <p>
                Debe coordinar con <i>Subastas Electrónicas</i> la edición y
                eliminación de videos e interacción con el público de sus
                transmisiones en vivo.
              </p>
            </li>
          </ul>
          <p className={"text-left"}>
            <b>Instrucciones:</b>
          </p>
          <ol className={"text-justify"}>
            <li>
              <p>
                Definir/Crear los siguientes datos para crear la transmisión:
              </p>
              <ol type={"a"}>
                <li>
                  <p>
                    <b>Título.</b>
                  </p>
                </li>
                <li>
                  <p>
                    <b>Descripción.</b>
                  </p>
                </li>
                <li>
                  <p>
                    <b>Fecha y hora de inicio.</b>
                  </p>
                </li>
                <li>
                  <p>
                    <b>Imagen de vista previa</b> (se mostrará en lugar del
                    video antes de la transmisión).
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                Contactarse con el servicio de atención al cliente de{" "}
                <i>Subastas Electrónicas</i>, expresar el deseo de crear una
                transmisión en vivo (se solicitarán los datos del paso
                anterior).
              </p>
            </li>
            <li>
              <p>
                Se creará la transmisión en YouTube y se le dará un código de
                video.
              </p>
            </li>
            <li>
              <p>
                Iniciar sesión con su cuenta de martillero en
                <a
                  target={"blank"}
                  className={"btn btn-link btn-sm"}
                  href={sources[site].url}
                >
                  {sources[site].name}
                </a>
              </p>
            </li>
            <li>
              <p>
                Ir a la página del bien objeto de la transmisión y hacer clic en
                el botón “Configurar transmisión en vivo”.
                <br />
                <div className={"text-center"}>
                  <div
                    className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                    onClick={() => {
                      setshow_setBroadcastBtn(!show_setBroadcastBtn);
                    }}
                  >
                    {`${
                      show_setBroadcastBtn ? "Ocultar" : "Mostrar"
                    } captura de pantalla`}
                  </div>
                </div>
                <Collapse id={"setBroadcastBtn"} isOpen={show_setBroadcastBtn}>
                  <img
                    className={"img img-responsive"}
                    style={{ width: "80vh" }}
                    src={setBroadcastBtn}
                  />
                  <br />
                </Collapse>
                Completar los campos requeridos con la fecha/hora de inicio, el
                código recibido en el paso anterior y seleccionar si la
                transmisión se aplica sólo a ese bien o a todos los bienes de la
                subasta.
                <br />
                <div className={"text-center"}>
                  <div
                    className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                    onClick={() => {
                      setshow_setBroadcastForm(!show_setBroadcastForm);
                    }}
                  >
                    {`${
                      show_setBroadcastForm ? "Ocultar" : "Mostrar"
                    } captura de pantalla`}
                  </div>
                </div>
                <Collapse
                  id={"setBroadcastForm"}
                  isOpen={show_setBroadcastForm}
                >
                  <img
                    className={"img img-responsive"}
                    style={{ width: "80vh" }}
                    src={setBroadcastForm}
                  />
                </Collapse>
              </p>
            </li>
            <li>
              <p>
                Instalar el programa{" "}
                <a
                  target={"blank"}
                  className={"btn btn-link btn-sm"}
                  href={"https://obsproject.com/es"}
                >
                  OBS
                </a>
                y familiarizarse con su interfaz.
              </p>
            </li>
            <li>
              <p>
                Una hora antes de la transmisión, nos pondremos en contacto con
                Ud. para coordinarla. Recibirá una clave que debe usar para
                conectar la transmisión en YouTube con el stream generado por
                OBS.
              </p>
            </li>
            <li>
              <p>
                Abrir OBS, hacer clic en “Archivo” -> “Configuración”, en el
                menú de la izquierda, seleccionar “Emisión” y colocar la clave
                de retransmisión del paso anterior.
                <br />
                <div className={"text-center"}>
                  <div
                    className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                    onClick={() => {
                      setshow_setOBSConf(!show_setOBSConf);
                    }}
                  >
                    {`${
                      show_setOBSConf ? "Ocultar" : "Mostrar"
                    } captura de pantalla`}
                  </div>
                </div>
                <Collapse id={"setOBSConf"} isOpen={show_setOBSConf}>
                  <img
                    className={"img img-responsive"}
                    style={{ width: "80vh" }}
                    src={setOBSConf}
                  />
                </Collapse>
              </p>
            </li>
            <li>
              <p>
                Hacer clic en “Ok” para guardar los cambios. Luego hacer clic en
                el botón “Iniciar transmisión”. Esto último no iniciará la
                transmisión, sólo empezará a enviar video a nuestra cuenta de
                YouTube. Una vez hecho esto podemos confirmar que la
                configuración es correcta y, a la hora indicada iniciaremos la
                transmisión.
              </p>
            </li>
          </ol>
        </CardBody>
      </Collapse>
    </Card>
  );
};

const ModoTransmision2 = ({ handleOpenTransmision, isOpen }) => {
  const [show_YTStudioCreateBtn, setshow_YTStudioCreateBtn] = useState(false);
  const [show_YTCellValidation, setshow_YTCellValidation] = useState(false);
  const [show_YTStudioFirstTime, setshow_YTStudioFirstTime] = useState(false);
  const [show_softwareless, setshow_softwareless] = useState(false);
  const [show_software, setshow_software] = useState(false);

  const [show_YTStudioWebcam, setshow_YTStudioWebcam] = useState(false);
  const [show_YTStudioWebcamPreview, setshow_YTStudioWebcamPreview] =
    useState(false);
  const [show_YTStudioCreateBtn2, setshow_YTStudioCreateBtn2] = useState(false);
  const [show_YTStudioScheduleBtn, setshow_YTStudioScheduleBtn] =
    useState(false);

  const [show_YTStudioNTMenu, setshow_YTStudioNTMenu] = useState(false);
  const [show_YTStudioDashboard, setshow_YTStudioDashboard] = useState(false);
  const [show_setOBSService, setshow_setOBSService] = useState(false);
  const [show_setOBSKey, setshow_setOBSKey] = useState(false);
  const [show_OBSStreamBtn, setshow_OBSStreamBtn] = useState(false);
  const [show_YTStudioShareBtn, setshow_YTStudioShareBtn] = useState(false);
  const [show_setBroadcastBtn, setshow_setBroadcastBtn] = useState(false);
  const [show_setBroadcastForm, setshow_setBroadcastForm] = useState(false);

  //  false,
  // : false,
  // : false,
  // : false,
  // : false,
  // : false,

  return (
    <Card className={`${styles["tip-card"]}`}>
      <CardHeader className={`${background} ${styles["tip-card__header"]}`}>
        <CardTitle className={`${styles["tip-card__header__title"]}`}>
          <h5 className={"text-white"}>
            Modo 2: Usando una cuenta propia de YouTube
          </h5>
        </CardTitle>
        <div
          className="btn btn-sm btn-primary "
          onClick={() => {
            handleOpenTransmision(2, !isOpen);
          }}
        >
          {isOpen ? "Ocultar" : "Mostrar"}
        </div>
      </CardHeader>
      <Collapse className={"text-center"} isOpen={isOpen}>
        <CardBody className={"bg-secondary"}>
          {/*<h5 className={'text-center'}>Modo 2: Usando una cuenta propia de YouTube</h5>*/}
          <p className={"text-left"}>
            <b>Ventajas:</b>
          </p>
          <ul className={"text-justify"}>
            <li>
              <p>
                Se usa un canal propio del martillero lo que le da un control
                total sobre los videos que sube a su canal, pudiendo editar y
                eliminar videos, además de controlar la interacción con sus
                suscriptores y público.
              </p>
            </li>
            <li>
              <p>
                Permite usar directamente la cámara y micrófono instalados en la
                PC sin necesidad de instalar un programa de Broadcasting.
              </p>
            </li>
            <li>
              <p>
                Asimismo permite usar un programa de Broadcasting si es
                requerido.
              </p>
            </li>
          </ul>
          <p className={"text-left"}>
            <b>Desventajas:</b>
          </p>
          <ul className={"text-justify"}>
            <li>
              <p>
                Requiere aprender a usar la interfaz de YouTube Studio para
                configurar las transmisiones.
              </p>
            </li>
          </ul>
          <p className={"text-left"}>
            <b>Instrucciones:</b>
          </p>
          <ol className={"text-justify"}>
            <li>
              <p>
                Iniciar sesión en
                <a
                  target={"blank"}
                  className={"btn btn-link btn-sm"}
                  href={"https://studio.youtube.com/"}
                >
                  YouTube Studio
                </a>
                con su cuenta de gmail. Si no tiene un canal creado, YouTube le
                pedirá que cree uno haciendo clic en el botón “CREAR CANAL”.
              </p>
            </li>
            <li>
              <p>
                En la esquina superior derecha de la pantalla, hacer clic en el
                botón “CREAR” y luego en “Transmitir en vivo”.
                <br />
                <div className={"text-center"}>
                  <div
                    className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                    onClick={() => {
                      setshow_YTStudioCreateBtn(!show_YTStudioCreateBtn);
                    }}
                  >
                    {`${
                      show_YTStudioCreateBtn ? "Ocultar" : "Mostrar"
                    } captura de pantalla`}
                  </div>
                </div>
                <Collapse
                  id={"YTStudioCreateBtn"}
                  isOpen={show_YTStudioCreateBtn}
                >
                  <img
                    className={"img img-responsive"}
                    style={{ width: "80vh" }}
                    src={YTStudioCreateBtn}
                  />
                </Collapse>
              </p>
            </li>
            <li>
              <p>
                Si nunca ha validado su cuenta, la transmisión estará
                deshabilitada, en este caso, deberá hacer clic en el botón
                “Habilitar” y luego proceder a verificar su cuenta con un número
                de celular con el 0 y el 15.
                <br />
                <div className={"text-center"}>
                  <div
                    className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                    onClick={() => {
                      setshow_YTCellValidation(!show_YTCellValidation);
                    }}
                  >
                    {`${
                      show_YTCellValidation ? "Ocultar" : "Mostrar"
                    } capturas de pantalla`}
                  </div>
                </div>
                <Collapse
                  id={"YTStudioCreateBtn"}
                  isOpen={show_YTCellValidation}
                >
                  <img
                    className={"img img-responsive"}
                    style={{ width: "80vh" }}
                    src={YTCellValidation1}
                  />
                  <br />
                  <br />
                  <img
                    className={"img img-responsive"}
                    style={{ width: "80vh" }}
                    src={YTCellValidation2}
                  />
                </Collapse>
              </p>
            </li>
            <li>
              <p>
                Si es la primera vez que crea una transmisión, le aparecerá una
                pantalla en la que tiene que seleccionar entre “Iniciar
                transmisión ahora mismo” y “Más adelante”, debe seleccionar “Más
                adelante”. Luego debe decidir si usará la “cámara web integrada”
                o un “software de transmisión”.
                <br />
                <div className={"text-center"}>
                  <div
                    className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                    onClick={() => {
                      setshow_YTStudioFirstTime(!show_YTStudioFirstTime);
                    }}
                  >
                    {`${
                      show_YTStudioFirstTime ? "Ocultar" : "Mostrar"
                    } capturas de pantalla`}
                  </div>
                </div>
                <Collapse
                  id={"YTStudioFirstTime"}
                  isOpen={show_YTStudioFirstTime}
                >
                  <img
                    className={"img img-responsive"}
                    style={{ width: "80vh" }}
                    src={YTStudioFirstTime1}
                  />
                  <br />
                  <br />
                  <img
                    className={"img img-responsive"}
                    style={{ width: "80vh" }}
                    src={YTStudioFirstTime2}
                  />
                </Collapse>
              </p>
            </li>
            <li>
              <p>
                Dependiendo de si se quiere usar un programa de Broadcasting,
                debe elegir una de las siguientes:
              </p>
              <ol type={"a"}>
                <li>
                  <div
                    className={"btn btn-sm btn-outline-primary"}
                    onClick={() => {
                      setshow_softwareless(!show_softwareless);
                    }}
                  >
                    Sin programa:
                  </div>
                  <Collapse id={"softwareless"} isOpen={show_softwareless}>
                    <ol type={"i"}>
                      <li>
                        <p>
                          En el menú de la izquierda, seleccionar el ícono
                          “Cámara web”, se abrirá un formulario para añadir
                          título del vídeo, si es una transmisión pública o
                          privada (puede usarse la transmisión privada para
                          realizar pruebas).
                          <br />
                          <div className={"text-center"}>
                            <div
                              className={`btn btn-sm btn-outline-${theme}  d-none d-sm-block`}
                              onClick={() => {
                                setshow_YTStudioWebcam(!show_YTStudioWebcam);
                              }}
                            >
                              {`${
                                show_YTStudioWebcam ? "Ocultar" : "Mostrar"
                              } capturas de pantalla`}
                            </div>
                          </div>
                          <Collapse
                            id={"YTStudioWebcam"}
                            isOpen={show_YTStudioWebcam}
                          >
                            <img
                              className={"img img-responsive"}
                              style={{ width: "80vh" }}
                              src={YTStudioWebcam1}
                            />
                            <br />
                            <br />
                            <img
                              className={"img img-responsive"}
                              style={{ width: "80vh" }}
                              src={YTStudioWebcam2}
                            />
                          </Collapse>
                        </p>
                      </li>
                      <li>
                        <p>
                          Se deberá seleccionar la opción “Programar para más
                          adelante” y colocar una fecha/hora de inicio para la
                          transmisión (esto es para configurar y publicitar con
                          tiempo la transmisión).
                        </p>
                      </li>
                      <li>
                        <p>
                          Marcar las casillas “no es contenido creado para
                          niños” y “no restringir mi video para usuarios mayores
                          de 18 años”.
                        </p>
                      </li>
                      <li>
                        <p>
                          En el botón “Más opciones” Se podrá agregar una
                          descripción (aparecerá bajo el video en el sitio de
                          YouTube), una categoría (para el buscador de YouTube)
                          y seleccionar la cámara y micrófono que va a
                          utilizarse. Todo esto puede ser editado más tarde,
                          antes de la transmisión.
                        </p>
                      </li>
                      <li>
                        <p>
                          Al hacer clic en el botón siguiente, YouTube usará la
                          cámara conectada al PC para tomar una fotografía y
                          usarlo como imagen a mostrar en lugar del video,
                          previo a la transmisión.
                        </p>
                      </li>
                      <li>
                        <p>
                          Luego aparecerá la vista previa de la transmisión,
                          donde se puede ver la configuración hecha en los pasos
                          anteriores, también aparece un botón “EDITAR”, que
                          permite editar todos estos parámetros, incluyendo la
                          imagen tomada por YouTube en el paso anterior y
                          reemplazarla por otra subiendo una imagen desde la PC.
                          <br />
                          <div className={"text-center"}>
                            <div
                              className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                              onClick={() => {
                                setshow_YTStudioWebcamPreview(
                                  !show_YTStudioWebcamPreview
                                );
                              }}
                            >
                              {`${
                                show_YTStudioWebcamPreview
                                  ? "Ocultar"
                                  : "Mostrar"
                              } captura de pantalla`}
                            </div>
                          </div>
                          <Collapse
                            id={"YTStudioWebcamPreview"}
                            isOpen={show_YTStudioWebcamPreview}
                          >
                            <img
                              className={"img img-responsive"}
                              style={{ width: "80vh" }}
                              src={YTStudioWebcamPreview}
                            />
                          </Collapse>
                        </p>
                      </li>
                      <li>
                        <p>
                          Hacer clic en el botón “Compartir” y luego en el ícono
                          “Copiar vínculo del video”. Luego hacer clic en el
                          botón "LISTO".
                        </p>
                      </li>
                    </ol>
                  </Collapse>
                </li>
                <li>
                  <div
                    className={"btn btn-sm btn-outline-primary"}
                    onClick={() => {
                      setshow_software(!show_software);
                    }}
                  >
                    Con programa
                  </div>
                  (debe tener instalado
                  <a
                    target={"blank"}
                    className={"btn btn-link btn-sm"}
                    href={"https://obsproject.com/es"}
                  >
                    OBS
                  </a>
                  ):
                  <Collapse isOpen={show_software}>
                    <ol type={"i"}>
                      <br />
                      <div className={"alert alert-primary"}>
                        Antes de decidir usar OBS, recomendamos tener en cuenta
                        que esta opción sólo es útil si se quieren usar las
                        opciones avanzadas de edición que este programa ofrece.
                        Puede instalarlo y probarlo sin necesidad de hacer una
                        transmisión para aprender a usarlo.
                      </div>
                      <br />
                      <li>
                        <p>
                          Iniciar sesión en{" "}
                          <a
                            target={"blank"}
                            className={"btn btn-link btn-sm"}
                            href={"https://studio.youtube.com/"}
                          >
                            YouTube Studio
                          </a>
                          hacer click en “CREAR” y “Transmisión en vivo”.
                          <br />
                          <div className={"text-center"}>
                            <div
                              className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                              onClick={() => {
                                setshow_YTStudioCreateBtn2(
                                  !show_YTStudioCreateBtn2
                                );
                              }}
                            >
                              {`${
                                show_YTStudioCreateBtn2 ? "Ocultar" : "Mostrar"
                              } captura de pantalla`}
                            </div>
                          </div>
                          <Collapse
                            id={"YTStudioCreateBtn"}
                            isOpen={show_YTStudioCreateBtn2}
                          >
                            <img
                              className={"img img-responsive"}
                              style={{ width: "80vh" }}
                              src={YTStudioCreateBtn}
                            />
                          </Collapse>
                        </p>
                      </li>
                      <li>
                        <p>
                          En el menú de la izquierda, seleccionar el ícono
                          “Administrar” y hacer clic en el botón “PROGRAMAR
                          TRANSMISIÓN”
                          <br />
                          <div className={"text-center"}>
                            <div
                              className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                              onClick={() => {
                                setshow_YTStudioScheduleBtn(
                                  !show_YTStudioScheduleBtn
                                );
                              }}
                            >
                              {`${
                                show_YTStudioScheduleBtn ? "Ocultar" : "Mostrar"
                              } captura de pantalla`}
                            </div>
                          </div>
                          <Collapse
                            id={"YTStudioScheduleBtn"}
                            isOpen={show_YTStudioScheduleBtn}
                          >
                            <img
                              className={"img img-responsive"}
                              style={{ width: "80vh" }}
                              src={YTStudioScheduleBtn}
                            />
                          </Collapse>
                          , se abrirá un formulario para añadir título del
                          vídeo, si es una transmisión pública o privada (puede
                          usarse la transmisión privada para realizar pruebas).
                          El botón “SUBIR MINIATURA PERSONALIZADA” permite
                          cambiar la imagen predeterminada que se muestra en
                          YouTube al buscar la transmisión.
                        </p>
                      </li>
                      <li>
                        <p>
                          Marcar las casillas “no es contenido creado para
                          niños” y “no restringir mi video para usuarios mayores
                          de 18 años”.
                          <br />
                          <div className={"text-center"}>
                            <div
                              className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                              onClick={() => {
                                setshow_YTStudioNTMenu(!show_YTStudioNTMenu);
                              }}
                            >
                              {`${
                                show_YTStudioNTMenu ? "Ocultar" : "Mostrar"
                              } captura de pantalla`}
                            </div>
                          </div>
                          <Collapse
                            id={"YTStudioNTMenu"}
                            isOpen={show_YTStudioNTMenu}
                          >
                            <img
                              className={"img img-responsive"}
                              style={{ width: "60vh" }}
                              src={YTStudioNTMenu}
                            />
                          </Collapse>
                        </p>
                      </li>
                      <li>
                        <p>
                          Hacer clic en “CREAR UNA TRASMISIÓN”. Aparecerá un
                          panel que permitirá conectar la transmisión en YouTube
                          con el stream de Video realizado con el programa de
                          Broadcasting seleccionado.
                        </p>
                      </li>
                      <li>
                        <p>
                          El botón “EDITAR”, le permitirá editar todos los
                          parámetros del paso anterior.
                        </p>
                      </li>
                      <li>
                        <p>
                          En la pestaña “Configuración de la transmisión”, hacer
                          clic en el botón “COPIAR” que está al lado de la clave
                          de transmisión (la misma se encuentra oculta).
                          <br />
                          <div className={"text-center"}>
                            <div
                              className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                              onClick={() => {
                                setshow_YTStudioDashboard(
                                  !show_YTStudioDashboard
                                );
                              }}
                            >
                              {`${
                                show_YTStudioDashboard ? "Ocultar" : "Mostrar"
                              } captura de pantalla`}
                            </div>
                          </div>
                          <Collapse
                            id={"YTStudioDashboard"}
                            isOpen={show_YTStudioDashboard}
                          >
                            <img
                              className={"img img-responsive"}
                              style={{ width: "80vh" }}
                              src={YTStudioDashboard}
                            />
                          </Collapse>
                        </p>
                      </li>
                      <li>
                        <p>
                          Abrir OBS, hacer clic en “Archivo” -> “Configuración”,
                          en el menú de la izquierda, seleccionar “Emisión”.
                        </p>
                      </li>
                      <li>
                        <p>
                          En “Servicio”, seleccionar “YouTube - RTMPS”
                          <br />
                          <div className={"text-center"}>
                            <div
                              className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                              onClick={() => {
                                setshow_setOBSService(!show_setOBSService);
                              }}
                            >
                              {`${
                                show_setOBSService ? "Ocultar" : "Mostrar"
                              } captura de pantalla`}
                            </div>
                          </div>
                          <Collapse
                            id={"setOBSService"}
                            isOpen={show_setOBSService}
                          >
                            <img
                              className={"img img-responsive"}
                              style={{ width: "80vh" }}
                              src={setOBSService}
                            />
                          </Collapse>
                        </p>
                      </li>
                      <li>
                        <p>
                          En “Clave de retransmisión” colocar la clave de
                          retransmisión copiada en YouTube Studio.
                          <br />
                          <div className={"text-center"}>
                            <div
                              className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                              onClick={() => {
                                setshow_setOBSKey(!show_setOBSKey);
                              }}
                            >
                              {`${
                                show_setOBSKey ? "Ocultar" : "Mostrar"
                              } captura de pantalla`}
                            </div>
                          </div>
                          <Collapse id={"setOBSKey"} isOpen={show_setOBSKey}>
                            <img
                              className={"img img-responsive"}
                              style={{ width: "80vh" }}
                              src={setOBSKey}
                            />
                          </Collapse>
                        </p>
                      </li>
                      <li>
                        <p>
                          Hacer clic en “Aplicar” para guardar los cambios y
                          “Aceptar” para cerrar el menú.
                        </p>
                      </li>
                      <li>
                        <p>
                          En OBS, hacer clic en “Iniciar Transmisión”. Si la
                          clave fue copiada correctamente, en YouTube Studio, se
                          verá el video transmitido por OBS. Pero la transmisión
                          no comenzará hasta que haga clic en el botón
                          “TRANSMITIR EN VIVO” en YouTube Studio, pero todavía
                          no es tiempo para esto.
                          <br />
                          <div className={"text-center"}>
                            omendaciones
                            <div
                              className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                              onClick={() => {
                                setshow_OBSStreamBtn(!show_OBSStreamBtn);
                              }}
                            >
                              {`${
                                show_OBSStreamBtn ? "Ocultar" : "Mostrar"
                              } captura de pantalla`}
                            </div>
                          </div>
                          <Collapse
                            id={"OBSStreamBtn"}
                            isOpen={show_OBSStreamBtn}
                          >
                            <img
                              className={"img img-responsive"}
                              style={{ width: "80vh" }}
                              src={OBSStreamBtn}
                            />
                          </Collapse>
                        </p>
                      </li>
                      <li>
                        <p>
                          En YouTube Studio, hacer clic en el botón "compartir"
                          que está al lado del botón “TRANSMITIR EN VIVO” y
                          luego en el ícono “copiar vínculo del video”. Luego
                          hacer click en el botón “CERRAR”.
                          <br />
                          <div className={"text-center"}>
                            <div
                              className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                              onClick={() => {
                                setshow_YTStudioShareBtn(
                                  !show_YTStudioShareBtn
                                );
                              }}
                            >
                              {`${
                                show_YTStudioShareBtn ? "Ocultar" : "Mostrar"
                              } capturas de pantalla`}
                            </div>
                          </div>
                          <Collapse
                            id={"YTStudioShareBtn"}
                            isOpen={show_YTStudioShareBtn}
                          >
                            <img
                              className={"img img-responsive"}
                              style={{ width: "60vh" }}
                              src={YTStudioShareBtn1}
                            />
                            <br />
                            <br />
                            <img
                              className={"img img-responsive"}
                              style={{ width: "60vh" }}
                              src={YTStudioShareBtn2}
                            />
                          </Collapse>
                        </p>
                      </li>
                    </ol>
                  </Collapse>
                  <li>
                    <p>
                      Al haber realizado correctamente el paso anterior, puede
                      cerrar sesión de YouTube Studio, la transmisión ha sido
                      creada y configurada. Puede acceder en el futuro para
                      iniciarla o editarla.
                    </p>
                  </li>
                  <li>
                    <p>
                      Luego de programar la transmisión en YouTube, es necesario
                      iniciar sesión en{" "}
                      <a
                        target={"blank"}
                        className={"btn btn-link btn-sm"}
                        href={sources[site].url}
                      >
                        {sources[site].name}
                      </a>{" "}
                      con su cuenta de martillero.
                    </p>
                  </li>
                  <li>
                    <p>
                      Ir a la página del bien que es objeto de la transmisión.
                    </p>
                  </li>
                  <li>
                    <p>
                      Hacer clic en el botón “Configurar transmisión en vivo”.
                      <br />
                      <div className={"text-center"}>
                        <div
                          className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                          onClick={() => {
                            setshow_setBroadcastBtn(!show_setBroadcastBtn);

                            this.setState({
                              show_setBroadcastBtn:
                                !this.state.show_setBroadcastBtn,
                            });
                          }}
                        >
                          {`${
                            show_setBroadcastBtn ? "Ocultar" : "Mostrar"
                          } captura de pantalla`}
                        </div>
                      </div>
                      <Collapse
                        id={"setBroadcastBtn"}
                        isOpen={show_setBroadcastBtn}
                      >
                        <img
                          className={"img img-responsive"}
                          style={{ width: "80vh" }}
                          src={setBroadcastBtn}
                        />
                        <br />
                      </Collapse>
                    </p>
                  </li>
                  <li>
                    <p>
                      Colocar una fecha y hora de inicio (se usará para colocar
                      el reproductor de YouTube en la página del bien a la hora
                      indicada).
                      <br />
                      <div className={"text-center"}>
                        <div
                          className={`btn btn-sm btn-outlinomendacionese-${theme} d-none d-sm-block`}
                          onClick={() => {
                            setshow_setBroadcastForm(!show_setBroadcastForm);
                          }}
                        >
                          {`${
                            show_setBroadcastForm ? "Ocultar" : "Mostrar"
                          } captura de pantalla`}
                        </div>
                      </div>
                      <Collapse
                        id={"setBroadcastForm"}
                        isOpen={show_setBroadcastForm}
                      >
                        <img
                          className={"img img-responsive"}
                          style={{ width: "80vh" }}
                          src={setBroadcastForm}
                        />
                      </Collapse>
                    </p>
                  </li>
                  <li>
                    <p>
                      Pegar el vínculo copiado (al final del paso 5) en
                      “Código/Link” de video.
                    </p>
                  </li>
                  <li>
                    <p>
                      Seleccionar si la transmisión es sólo para ese bien o para
                      todos los bienes de la subasta (de esta forma sólo tiene
                      que configurar una transmisión para toda la subasta).
                    </p>
                  </li>
                  <li>
                    <p>
                      Al momento de iniciar la transmisión, debe iniciar sesión
                      nuevamente en
                      <a
                        target={"blank"}
                        className={"btn btn-link btn-sm"}
                        href={"https://studio.youtube.com"}
                      >
                        YouTube Studio
                      </a>
                      , hacer clic en “CREAR” y luego en “TRANSMITIR EN VIVO”.
                      <br />
                      <div className={"text-center"}>
                        <div
                          className={`btn btn-sm btn-outline-${theme} d-none d-sm-block`}
                          onClick={() => {
                            setshow_YTStudioCreateBtn2(
                              !show_YTStudioCreateBtn2
                            );
                          }}
                        >
                          {`${
                            show_YTStudioCreateBtn2 ? "Ocultar" : "Mostrar"
                          } captura de pantalla`}
                        </div>
                      </div>
                      <Collapse
                        id={"YTStudioCreateBtn"}
                        isOpen={show_YTStudioCreateBtn2}
                      >
                        <img
                          className={"img img-responsive"}
                          style={{ width: "80vh" }}
                          src={YTStudioCreateBtn}
                        />
                      </Collapse>
                    </p>
                  </li>
                  <li>
                    <p>
                      En el menú de la izquierda, hacer clic en el botón
                      “Administrar”.
                    </p>
                  </li>
                  <li>
                    <p>
                      Hacer clic en el título o la vista previa de de la
                      transmisión creada.
                    </p>
                  </li>
                  <li>
                    <p>
                      El botón “TRANSMITIR EN VIVO” iniciará la transmisión.
                    </p>
                  </li>
                </li>
              </ol>
            </li>
          </ol>
        </CardBody>
      </Collapse>
    </Card>
  );
};

const TipsTransmision = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card className={`${styles["tip-card"]}`}>
      <CardHeader className={`${background} ${styles["tip-card__header"]}`}>
        <CardTitle className={`${styles["tip-card__header__title"]}`}>
          <h5 className={"text-white"}>Recomendaciones</h5>
        </CardTitle>
        <button
          className={[`btn btn-sm btn-primary`]}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? "Ocultar" : "Mostrar"}
        </button>
      </CardHeader>
      <Collapse className={"text-center"} isOpen={isOpen}>
        <CardBody className={`bg-secondary ${styles["tip-card__body"]}`}>
          <ol className={"text-justify"}>
            <li>
              <p>
                Una vez iniciada, la transmisión no puede pausarse hasta la
                finalización.
              </p>
            </li>
            <li>
              <p>
                A medida que se acerca la hora de fin, aumenta la cantidad de
                espectadores, por lo que repetir información periódicamente es
                una buena idea.
              </p>
            </li>
            <li>
              <p>
                Siempre hay un delay de unos segundos entre lo que dice el
                martillero y lo que ve el público.
              </p>
            </li>
            <li>
              <p>Evite largos períodos de silencio.</p>
            </li>
          </ol>
        </CardBody>
      </Collapse>
    </Card>
  );
};

export { RequisitosTransmision, ModoTransmision, TipsTransmision };
