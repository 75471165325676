import React from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import AuctionsNavbar from "../../components/Navbars/AuctionsNavbar";
import SeFooter from "../../components/Footers/SeFooter";

import SaveInCalendar from "./SaveInCalendar";

import {
  EmailIcon,
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import { FacebookIcon, TwitterIcon, WhatsappIcon } from "react-share";
import TagManager from "react-gtm-module";
import {
  addMinutes,
  addToRoom,
  removeFromRoom,
  apiRequestHeaders,
  as_currency,
  as_datetime,
  calcNextCosts,
  containsObject,
  get,
  getRoom,
  getRooms,
  makeOffer,
  makeSecure,
  ping,
  removeMinutes,
  reverse,
  slugify,
  syncRoom,
  getNow,
  getCleanPhoneNumber,
  copyLinkToClipBoard,
} from "../../components/services";
import { renderConfirmModal } from "../../components/reactstrap_services";

import Timer from "../../components/Timer/Timer";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import {
  Map,
  Marker,
  Polygon,
  Popup,
  TileLayer,
} from "react-leaflet/dist/react-leaflet";
import Session from "../../components/session";
import { ToastContainer } from "react-toastr";
import { Helmet } from "react-helmet";
import SoldUpper from "assets/img/sold_upper.png";
import Desierto from "assets/img/desierto.png";
import Suspended from "assets/img/suspended.png";
import UnameReport from "components/UnameReport";
import { TipsTransmision } from "components/Help/HelpMartillero";
import { SimilarProducts } from "components/Good/SimilarProducts";
import { TimeContext } from "contexts/TimeContext";
import styles from "./style.module.scss";

class GoodView extends React.Component {
  static contextType = TimeContext;

  constructor(props) {
    super(props);

    this.hide_mo_btn = false;
    this.hide_stream_conf = true;
    this.g_date_opts = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    };
    this.q_date_opts = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    };

    this.s_date_opts = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    this.so_date_opts = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    this.state = {
      good: {},
      photo_index: 0,
      error: null,
      show_offers: false,
      activeTab: "description",
      user: {
        username: Session.getUsername(this.props.source),
        role: Session.getRole(this.props.source),
      },
      rooms: {
        cba: false,
        sl: false,
        privadas: false,
      },
      websocket: [],
      show_confirm_modal: false,
      new_offers: false,
      show_decree_act: false,
      show_decree: true,
      show_act: true,
      hide_costs: true,
      decree: null,
      ws_error: null,
      my_offer: null,
      // hide_stream_conf: true,
      stream_conf: {
        case: "good",
        start: "",
        video_id: "",
        success: false,
      },
      offer_enabled: true,
      in_room: false,
      updatedRooms: true,
      gadScript:false
    };

    this.intervals = [];
    this.mainInterval = null;

    this.setActiveTab = this.setActiveTab.bind(this);
    this.getQuestions = this.getQuestions.bind(this);
    this.renderCosts = this.renderCosts.bind(this);
    this.renderOffers = this.renderOffers.bind(this);
    this.renderQuestions = this.renderQuestions.bind(this);
    this.renderLocation = this.renderLocation.bind(this);
    this.renderMap = this.renderMap.bind(this);
    this.renderAuctioneer = this.renderAuctioneer.bind(this);
    this.closeWebsockets = this.closeWebsockets.bind(this);
    this.openWSocket = this.openWSocket.bind(this);
    this.messageReceived = this.messageReceived.bind(this);
    this.makeOffer = this.makeOffer.bind(this);
    this.createQuestion = this.createQuestion.bind(this);
    this.renderDisclaimer = this.renderDisclaimer.bind(this);
    this.userOffered = this.userOffered.bind(this);
    this.userLastOffered = this.userLastOffered.bind(this);
    this.renderConfirmModal = renderConfirmModal.bind(this);
    this.renderDecree = this.renderDecree.bind(this);
    this.renderRoomBtns = this.renderRoomBtns.bind(this);
    this.renderOfferBtn = this.renderOfferBtn.bind(this);
    this.renderShareBtn = this.renderShareBtn.bind(this);
    this.getRoom = getRoom.bind(this);
    this.getRooms = getRooms.bind(this);
    this.addToRoom = addToRoom.bind(this);
    this.removeFromRoom = removeFromRoom.bind(this);
    this.syncRoom = syncRoom.bind(this);
    this.getOffers = this.getOffers.bind(this);
    this.getMyOffer = this.getMyOffer.bind(this);
    this.offerActionBtn = this.offerActionBtn.bind(this);
    this.initStreamConf = this.initStreamConf.bind(this);
    this.renderStreamConf = this.renderStreamConf.bind(this);
    this.checkOffersLastMinute = this.checkOffersLastMinute.bind(this);
    this.toggleRoom = this.toggleRoom.bind(this);
  }

  clearIntervals(clearmain = false) {
    for (var interval of this.intervals) {
      clearInterval(interval);
    }
    this.intervals = [];
    if (clearmain && this.mainInterval) {
      clearInterval(this.mainInterval);
    }
  }

  checkOffersLastMinute() {
    if (this.state.good) {
      var end_date = new Date(this.state.good.end_date);
      var now = new Date(getNow(this.context.date, this.context.localTime));

      var diff = end_date - now;
      diff = Math.floor(diff / 1000);
      if (
        diff < 120 &&
        diff % 5 === 0 &&
        diff > -40 &&
        this.userLastOffered()
      ) {
        this.clearIntervals();
        this.getGoodData(true, false);
      }
    }
    return;
  }

  componentDidMount() {
    // ReactGA.pageview(window.location.pathname);
    this.getGoodData();
    this.getRooms();
    this.getMyOffer();
    this.getDeposit();
    this.getExemptions();
    this.mainInterval = setInterval(this.checkOffersLastMinute, 1000);
    if(!this.state.gadScript){
      const tagManagerArgs = {
        gtmId: this.props.sources[this.props.site].gtm_ID,
      };
      TagManager.initialize(tagManagerArgs);
      const script = document.createElement("script");
      script.innerHTML =
        "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-368678964');";
      document.head.appendChild(script);
    }

  }

  imThisAuct() {
    // Returns true if current user is the auctioneer
    // for this good.
    var res = false;
    var good = this.state.good;
    var session = Session.getSession()[this.props.source];
    var doc = session.doc;
    var public_doc = session.public_doc;
    if (good.auctioneer) {
      var cond1 = doc === good.auctioneer.doc;
      var cond2 = public_doc === good.auctioneer.public_doc;
      res = cond1 && cond2;
    }
    return res;
  }

  initStreamConf(good) {
    var stream_conf = this.state.stream_conf;
    if (good.start_date) {
      stream_conf["stream"] = good.stream;
      stream_conf["good"] = good.id;
      if (Object.keys(good.stream).length > 0) {
        var start_date = new Date(good.stream.start);
        var offset_mins = start_date.getTimezoneOffset();
        stream_conf["start"] = removeMinutes(start_date, offset_mins)
          .toISOString()
          .replace(":00.000Z", "");
        stream_conf["video_id"] = `${good.stream ? good.stream.video_id : ""}`;
        stream_conf["date_valid"] = true;
        stream_conf["video_valid"] = true;
        stream_conf["case"] = good.stream.auction !== null ? "auction" : "good";
      } else {
        stream_conf["start"] = new Date(good.start_date)
          .toISOString()
          .replace(":00.000Z", "");
        stream_conf["video_id"] = "";
        stream_conf["date_valid"] = false;
        stream_conf["video_valid"] = false;
        stream_conf["case"] = "good";
      }
    }
    return stream_conf;
  }

  renderStreamConf() {
    var stream_conf = this.state.stream_conf;
    var good = this.state.good;

    var check_date = () => {
      var stream_conf = this.state.stream_conf;
      var cond1 =
        new Date(stream_conf["start"]) <
        new Date(getNow(this.context.date, this.context.localTime));
      var cond2 = stream_conf["start"] !== "" && stream_conf["start"] !== null;
      stream_conf["date_error"] = cond1;
      stream_conf["date_valid"] = !cond1 && cond2;
      this.setState({ stream_conf: stream_conf });
    };

    var check_video = () => {
      var stream_conf = this.state.stream_conf;
      stream_conf.video_id = stream_conf.video_id.replace(
        "https://youtu.be/",
        ""
      );
      stream_conf.video_id = stream_conf.video_id.replace(
        "https://www.youtube.com/watch?v=",
        ""
      );
      var cond1 = !stream_conf.video_id.includes(" ");
      var cond2 =
        stream_conf["video_id"] !== "" && stream_conf["video_id"] !== null;
      stream_conf["video_error"] = !cond1;
      stream_conf["video_valid"] = cond1 && cond2;
      this.setState({ stream_conf: stream_conf });
    };

    var update = (e) => {
      var stream_conf = this.state.stream_conf;
      stream_conf[e.target.name] = e.target.value;
      stream_conf["success"] = false;
      stream_conf["cancelled"] = false;
      this.setState({ stream_conf: stream_conf });
    };

    var delete_stream = (e) => {
      var good = this.state.good;
      var args = good.stream.id ? [good.stream.id] : [];
      var url = reverse(
        "stream",
        this.props.source,
        args,
        {},
        this.props.sources
      );
      const requestOptions = {
        headers: apiRequestHeaders(Session.getToken(this.props.source)),
        method: "DELETE",
        mode: "cors",
      };
      fetch(url, requestOptions).then(
        (result) => {
          var stream_conf = this.state.stream_conf;
          stream_conf.cancelled = true;
          stream_conf.success = false;
          var good = this.state.good;
          good.stream = {};
          stream_conf = this.initStreamConf(good);
          this.setState({ stream_conf: stream_conf, good: good });
        },
        (error) => {
          this.setState({ error: error });
        }
      );
    };

    var submit_form = (e) => {
      e.preventDefault();
      var stream_conf = this.state.stream_conf;
      if (stream_conf.video_valid && stream_conf.date_valid) {
        var keys = ["start", "video_id", "good", "case"];
        var data = {};
        for (var i in keys) {
          var key = keys[i];
          data[key] = stream_conf[key];
        }
        var args = stream_conf.stream.id ? [stream_conf.stream.id] : [];
        var url = reverse(
          "stream",
          this.props.source,
          args,
          {},
          this.props.sources
        );
        const requestOptions = {
          headers: apiRequestHeaders(Session.getToken(this.props.source)),
          method: stream_conf.stream.id ? "PUT" : "POST",
          body: JSON.stringify(data),
          mode: "cors",
        };
        fetch(url, requestOptions)
          .then((res) => {
            if (res.status === 401) {
              window.location = reverse("logout");
            }
            return res.json();
          })
          .then(
            (result) => {
              var good = this.state.good;
              good.stream = result;
              var stream_conf = this.initStreamConf(good);
              stream_conf.success = true;
              this.setState({ stream_conf: stream_conf, good: good });
            },
            (error) => {
              this.setState({ error: error });
            }
          );
      }
    };

    var delbtn = null;
    if (good.stream && Object.keys(good.stream).length > 0) {
      delbtn = (
        <Button className={"btn-danger"} onClick={(e) => delete_stream()}>
          Cancelar transmisión en vivo.
        </Button>
      );
    }

    return (
      <Col
        xs={12}
        className={"bg-white m-3 text-center"}
        hidden={this.hide_stream_conf}
      >
        <br />
        <h3>Transmisión en vivo</h3>
        <p>Aquí podrá configurar la transmisión en vivo de la subasta.</p>
        <a
          className={`btn btn-sm btn-outline-${
            this.props.sources[this.props.site].theme_name
          }`}
          href={"/transmision_instructivo"}
        >
          Ver instructivo
        </a>
        <Form className={"p-3 text-center"} onSubmit={(e) => submit_form(e)}>
          <FormGroup row>
            <Label for="name" xs={4}>
              Fecha inicio:
            </Label>
            <Col xs={8}>
              <Input
                type="datetime-local"
                name="start"
                value={stream_conf["start"]}
                id="fecha_inicio"
                onChange={(e) => {
                  update(e);
                  check_date();
                }}
                valid={stream_conf["date_valid"]}
                invalid={stream_conf["date_error"]}
                required={true}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="email" xs={4}>
              Código/Link de video:
            </Label>
            <Col xs={8}>
              <Input
                type="text"
                name="video_id"
                value={stream_conf.video_id}
                id="video_id"
                placeholder=""
                onChange={(e) => {
                  update(e);
                  check_video();
                }}
                valid={stream_conf["video_valid"]}
                invalid={stream_conf["video_error"]}
                required={true}
              />
              <small>
                Vista previa en{" "}
                <a
                  href={`https://www.youtube.com/watch?v=${stream_conf.video_id}`}
                >{`https://www.youtube.com/watch?v=${stream_conf.video_id}`}</a>
              </small>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="subject" xs={4}>
              Alcance:
            </Label>
            <Col xs={8}>
              <Input
                type={"select"}
                name={"case"}
                id={"case"}
                onChange={(e) => {
                  update(e);
                }}
                valid={true}
                invalid={false}
                value={stream_conf.case === "good" ? "good" : "auction"}
              >
                <option value={"good"}>
                  Aplicar a este bien. (Sólo se verá en esta página)
                </option>
                <option value={"auction"}>
                  Aplicar a toda la subasta. (Se verá en todos los bienes de
                  esta subasta)
                </option>
              </Input>
            </Col>
          </FormGroup>
          <Col
            className={"text-center py-3"}
            xs={12}
            hidden={!(stream_conf.date_error || stream_conf.video_error)}
          >
            <div className={"alert alert-danger"}>
              <h4 className={"text-white"}>Error en los datos.</h4>
              <p className={"text-white"}>
                Las casillas de color rojo indican error en sus datos.
                <br />
                La fecha debe ser posterior a la fecha actual.
                <br />
                El código de video debe corresponder a una transmisión en vivo
                de Youtube.
                <br />
              </p>
            </div>
          </Col>
          <Col
            className={"text-center py-3"}
            xs={12}
            hidden={!stream_conf.success}
          >
            <div className={"alert alert-success"}>
              <h4 className={"text-white"}>Cambios guardados con éxito.</h4>
            </div>
          </Col>
          <Col
            className={"text-center py-3"}
            xs={12}
            hidden={!stream_conf.cancelled}
          >
            <div className={"alert alert-warning"}>
              <h4 className={"text-white"}>Transmision cancelada.</h4>
            </div>
          </Col>
          <Button
            className={
              stream_conf.date_valid && stream_conf.video_valid
                ? "btn-success"
                : "btn-secondary"
            }
            type={"submit"}
          >
            Guardar
          </Button>
          {delbtn}
        </Form>
        <TipsTransmision />
      </Col>
    );
  }

  renderDownloadImgs() {
    var res = null;
    var session = Session.getSession()[this.props.site];
    var photos = get(this.state.good, "photos", []);
    var links = [];
    // let usnamelwcase = session.username
    let usnamelwcase = session.username ? session.username.toLowerCase() : "";
    photos.forEach((f) => {
      links.push(
        <Col sm={3} key={f.url}>
          <a href={f.url} target={"_blank"}>
            <img src={f.url} className={"img img-thumbnail"} />
          </a>
        </Col>
      );
    });

    if (
      photos.length > 0 &&
      session &&
      usnamelwcase.includes("soporte") &&
      session.role === "AM"
    ) {
      res = (
        <div className={"container mt-4"} key={"DownloadImgs"}>
          <div
            className={"bg-white text-center"}
            role={"alert"}
            id={"alert_dl_imgs"}
          >
            <b>Descargar imágenes:</b>
            <br />
            <br />
            <Row>{links}</Row>
          </div>
        </div>
      );
    }
    return res;
  }

  renderDisclaimer() {
    var res = [];
    var session = Session.getSession();
    var username = session[this.props.source].username;
    var source_link = (
      <a href={this.props.sources[this.props.source].url}>
        {this.props.sources[this.props.source].name}
      </a>
    );
    if (Session.loggedIn() && !username) {
      res.push(
        <div className={"container mt-4"} key={"SessionAlert"}>
          <div
            className={
              "alert alert-warning alert-dismissible fade show text-center"
            }
            role={"alert"}
            id={"alert"}
          >
            <b>
              No has iniciado sesión con un usuario de {source_link}, por lo que
              no podrás ofertar en este lote.
            </b>
            <br />
            <b>
              Para poder hacerlo, sincroniza usuarios en{" "}
              <a href={"/panel_usuario/pass_usuario/"}>tu panel</a>
            </b>
          </div>
        </div>
      );
    }
    if (this.state.good.suspension) {
      res.push(
        <div className={"container mt-4"} key={"SuspensionAlert"}>
          <div
            className={
              "alert alert-warning alert-dismissible fade show text-center"
            }
            role={"alert"}
            id={"alert"}
          >
            <h5 className={"text-white"}>
              Subasta Suspendida (el{" "}
              {as_datetime(
                new Date(this.state.good.suspension.date),
                this.s_date_opts
              )}
              )
            </h5>
            <b>Motivo: </b>
            {get(this.state.good.suspension, "reason", "")}
            <br />
          </div>
        </div>
      );
    }
    return res;
  }

  createQuestion() {
    var args = [];
    var kwargs = {};
    var text = document.getElementById("questionForm").value;
    if (text !== "") {
      var payload = {
        good: this.props.gid,
        text: text,
      };
      var url = reverse(
        "question",
        this.props.source,
        args,
        kwargs,
        this.props.sources
      );
      const requestOptions = {
        method: "POST",
        headers: apiRequestHeaders(Session.getToken(this.props.source)),
        body: JSON.stringify(payload),
        mode: "cors",
      };
      fetch(url, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            window.location = reverse("logout");
          } else if (res.status === 400) {
            setTimeout(() => window.location.replace('/panel_usuario/'), 2000);
          }
          return res.json();
        })
        .then(
          (result) => {
            if (Object.keys(result).includes('error')){
              console.log(result);
              let errors = JSON.parse(result.error);
              errors.forEach((error) => {
                this.container.error(error, "Error", { closeButton: true });
              });
            }
            this.getQuestions();
          },
          (error) => {
            this.setState({ error: error });
          }
        );
    }
  }

  userOffered() {
    var res = false;
    var username = Session.getUsername(this.props.source);
    if (this.state.my_offer && this.state.my_offer.length > 0) {
      var user = this.state.my_offer[0].user;
      if (user === username) {
        res = true;
      }
    }
    // for (var index in this.state.good.offers) {
    //     var offer = this.state.good.offers[index];
    //     if (offer.user === username) {
    //         res = true;
    //         break;
    //     }
    // }
    return res;
  }

  userLastOffered() {
    var res = false;
    var username = Session.getUsername(this.props.source);
    if (this.state.my_offer && this.state.my_offer.length > 0) {
      var offer = this.state.my_offer[0];
      var user = offer.user;
      if (user === username) {
        res = offer.last;
      }
    }
    return res;
  }

  makeOffer() {
    if (this.state.offer_enabled) {
      this.setState({ offer_enabled: false });
      var gid = this.props.gid;
      var sources = this.props.sources;
      var source = this.props.source;
      var toast_container = this.container;
      makeOffer(gid, sources, source, toast_container);
      this.addToRoom(parseInt(this.props.gid), this.props.source);
      setTimeout(() => this.setState({ offer_enabled: true }), 5000);
    }
  }

  setActiveTab(tabId) {
    if (this.state.activetab !== tabId) {
      this.setState({ activeTab: tabId });
    }
  }

  createDeposit() {
    this.setState({
      creating_deposit: true,
    });
    var data = {
      bien_id: this.props.gid,
    };
    var url = reverse("deposit", this.props.source, [], {}, this.props.sources);
    const requestOptions = {
      method: "POST",
      headers: apiRequestHeaders(Session.getToken(this.props.source)),
      body: JSON.stringify(data),
      mode: "cors",
    };
    fetch(url, requestOptions)
      .then((res) => {
        if (res.status === 401) {
          window.location = reverse("logout");
        }
        return res.json();
      })
      .then(
        (result) => {
          result["status"] = {
            style: "warning",
            trans: "pendiente",
            desc: "El usuario aún no completó el proceso de pago",
          };
          this.setState({
            deposit: result,
          });
          window.location = reverse(
            "deposito_usuario",
            this.props.source,
            [],
            {},
            this.props.sources
          );
        },
        (error) => {
          this.setState({ error: error });
        }
      );
  }

  getDeposit() {
    if (Session.loggedIn()) {
      var args = [];
      var kwargs = {
        bien_id: this.props.gid,
      };
      var url = reverse(
        "deposit",
        this.props.source,
        args,
        kwargs,
        this.props.sources
      );
      const requestOptions = {
        method: "GET",
        headers: apiRequestHeaders(Session.getToken(this.props.source)),
        mode: "cors",
      };
      fetch(url, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            window.location = reverse("logout");
          }
          return res.json();
        })
        .then(
          (result) => {
            if (result.results && result.results.length > 0) {
              var deposit = result.results[0];
              if (deposit) {
                this.setState({
                  deposit: deposit,
                });
              }
            }
          },
          (error) => {
            // this.setState({error:error});
            console.log("");
          }
        );
    }
  }

  getExemptions() {
    if (Session.loggedIn()) {
      var url = reverse(
        "exemption",
        this.props.source,
        [],
        {},
        this.props.sources
      );
      const requestOptions = {
        method: "GET",
        headers: apiRequestHeaders(Session.getToken(this.props.source)),
        mode: "cors",
      };
      fetch(url, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            window.location = reverse("logout");
          }
          return res.json();
        })
        .then(
          (result) => {
            if (result.results && result.results[0].exemptions) {
              this.setState({
                exemptions: result.results[0].exemptions,
              });
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              // error: error,
            });
          }
        );
    }
  }

  getGoodData = (refresh = false, offer_msg = false) => {
    var sources = Object.keys(this.props.sources);
    if (containsObject(this.props.source, sources)) {
      var args = [this.props.gid];
      var kwargs = refresh ? { case: "update" } : {};
      var url = reverse(
        "public_good",
        this.props.source,
        args,
        kwargs,
        this.props.sources
      );
      const requestOptions = {
        method: "GET",
        headers: apiRequestHeaders(Session.getToken(this.props.source)),
        mode: "cors",
      };
      fetch(url, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            window.location = reverse("logout");
          }
          return res.json();
        })
        .then(
          (result) => {
            var good = this.state.good;
            if (refresh) {
              delete result.photos;
            }
            delete result.offers;
            good = Object.assign({}, good, result);
            if (good.status === "hidden") {
              window.location = "/";
            }
            // this.setState({good: good});
            var stream_conf = this.initStreamConf(good);
            this.setState({ stream_conf: stream_conf, good: good });
            // this.getQuestions();
            this.closeWebsockets();
            this.openWSocket();
            if (offer_msg) {
              var toast = `${result.last_oferent} ofertó ${as_currency(
                result.price
              )} en ${good.name}`;
              if (
                this.container &&
                result.last_oferent != this.state.user.username
              ) {
                this.container.error(toast, "Oferta", { closeButton: true });
              }
            }
            if (!refresh) {
              this.intervals.push(
                setInterval(() => {
                  var good = this.state.good;
                  var finished =
                    new Date(good.end_date) <
                    new Date(getNow(this.context.date, this.context.localTime));
                  if (
                    navigator.onLine &&
                    this.state &&
                    this.state.websocket.length > 0 &&
                    this.state.websocket[0].readyState !== 3
                  ) {
                    var url = this.props.sources[this.props.site].url;
                    ping.get(this.props.site, url, (state) => {
                      state === "timeout"
                        ? this.setState({ ws_error: true })
                        : console.log(url, state);
                    });
                  }
                  if (
                    !finished &&
                    (!navigator.onLine ||
                      (this.state &&
                        this.state.websocket.length > 0 &&
                        this.state.websocket[0].readyState === 3))
                  ) {
                    this.setState({ ws_error: true });
                  }
                }, 5000)
              );
              if (
                this.state.good.stream &&
                Object.keys(this.state.good.stream).length !== 0 &&
                this.imThisAuct()
              ) {
                this.getOffers();
              }
            }
          },
          (error) => {
            this.setState({ error: error });
          }
        );
    }
  };

  getDecree() {
    var sources = Object.keys(this.props.sources);
    if (
      containsObject(this.props.source, sources) &&
      get(this.state.good, "decree", false)
    ) {
      var args = [this.state.good.decree.id];
      var kwargs = {};
      var url = reverse(
        "public_decree",
        this.props.source,
        args,
        kwargs,
        this.props.sources
      );
      const requestOptions = {
        method: "GET",
        headers: apiRequestHeaders(Session.getToken(this.props.source)),
      };
      fetch(url, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            window.location = reverse("logout");
          }
          return res.json();
        })
        .then(
          (result) => {
            this.setState({ decree: result });
          },
          (error) => {
            this.setState({ error: error });
          }
        );
    }
  }

  getQuestions() {
    var sources = Object.keys(this.props.sources);
    if (containsObject(this.props.source, sources)) {
      var kwargs = { product_id: this.props.gid };
      var url = reverse(
        "public_question",
        this.props.source,
        [],
        kwargs,
        this.props.sources
      );
      const requestOptions = {
        method: "GET",
        headers: apiRequestHeaders(Session.getToken(this.props.source)),
      };
      fetch(url, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            window.location = reverse("logout");
          }
          return res.json();
        })
        .then(
          (result) => {
            this.setState({ questions: result });
          },
          (error) => {
            this.setState({ error: error });
          }
        );
    }
  }

  getOffers(next = false) {
    var sources = Object.keys(this.props.sources);
    if (containsObject(this.props.source, sources)) {
      var kwargs = { product_id: this.props.gid };
      var url = this.state.next_offers;
      if (!next) {
        url = reverse(
          "public_offer",
          this.props.source,
          [],
          kwargs,
          this.props.sources
        );
      }
      const requestOptions = {
        method: "GET",
        headers: apiRequestHeaders(Session.getToken(this.props.source)),
      };
      fetch(url, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            window.location = reverse("logout");
          }
          return res.json();
        })
        .then(
          (result) => {
            var state = this.state;
            if (next) {
              for (var i in result.results) {
                var offer = result.results[i];
                if (containsObject(offer, state.good.offers)) {
                  result.results.splice(i, 1);
                }
              }
              state.good.offers = state.good.offers.concat(result.results);
            } else {
              state.good.offers = result.results;
            }
            state.next_offers = makeSecure(result.next);
            state.new_offers = false;
            this.hide_mo_btn = false;
            this.setState(state);
          },
          (error) => {
            this.setState({ offers_error: error, error: error });
            // setTimeout(() => this.getOffers(), 2000);
          }
        );
    }
  }

  getMyOffer() {
    var sources = Object.keys(this.props.sources);
    if (containsObject(this.props.source, sources)) {
      var kwargs = {
        product_id: this.props.gid,
        case: "my_offer",
      };
      var url = reverse(
        "public_offer",
        this.props.source,
        [],
        kwargs,
        this.props.sources
      );
      const requestOptions = {
        method: "GET",
        headers: apiRequestHeaders(Session.getToken(this.props.source)),
      };
      fetch(url, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            window.location = reverse("logout");
          }
          return res.json();
        })
        .then(
          (result) => {
            this.setState({ my_offer: result.results });
          },
          (error) => {
            this.setState({ offers_error: error, error: error });
            // setTimeout(() => this.getMyOffer(), 2000);
          }
        );
    }
  }

  offerActionBtn() {
    this.hide_mo_btn = true;
    if (this.state.new_offers) {
      this.getOffers();
    } else if (this.state.next_offers) {
      this.getOffers(true);
      this.setState({ show_offers: true });
    } else {
      this.hide_mo_btn = false;
      this.setState({ show_offers: !this.state.show_offers });
    }
  }

  renderAuctioneer() {
    const wsp_url = "https://wa.me/";
    var res = null;
    const auct = get(this.state.good, "auctioneer", false);
    let allowbtn;
    if (auct) {
      let num = 549 + getCleanPhoneNumber(auct.phone);
 
      if (num.length == 13){
        allowbtn = ( <a
        href={`${wsp_url + "549" + getCleanPhoneNumber(auct.phone)}`}
        target="_blank"
        className="btn btn-sm btn-success"
        >
        {auct.phone}{" "}
        <i className="fa fa-whatsapp text-white" aria-hidden="true"></i>
        </a>
        );
      } else{
        allowbtn = <b>{auct.phone}{" "}</b>;
      }
      res = (
        <Row className={"bg-white"}>
          <Col className={"text-center"} md={6}>
            <img
              src={auct.photo}
              className={"img img-responsive my-3"}
              style={{ width: "25vh" }}
            />
          </Col>
          <Col md={6}>
            <br />
            <p>
              <b>Martillero:</b> {auct.name}
            </p>
            <p>
              <b>Matrícula:</b> {auct.doc}
            </p>
            {auct.public_doc ? (
              <p>
                <b>Matricula pública:</b> {auct.public_doc}
              </p>
            ) : null}
            <p>
              <b>Teléfono: </b>{" "}
              {allowbtn}
            </p>
          </Col>
        </Row>
      );
    }
    return res;
  } 
  renderMap() {
    var res = null;
    var location = get(this.state.good, "location", null);
    if (location) {
      var g_coords = location.g_lat && location.g_lng;
      var a_coords = location.a_lat && location.a_lng;
      var terrain = location.map.length > 0;
      var zoom = 16;
      var position = null;
      if (g_coords) {
        position = [location.g_lat, location.g_lng];
      } else if (a_coords) {
        position = [location.a_lat, location.a_lng];
      }
      if (terrain) {
        var polygon = [];
        for (var i in location.map) {
          var coords = location.map[i];
          polygon[i] = [parseFloat(coords.lat), parseFloat(coords.lng)];
        }
        res = (
          <Map center={polygon[0]} zoom={zoom}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <Polygon positions={polygon} color={"red"} />
          </Map>
        );
      } else if (position) {
        res = (
          <Map center={position} zoom={zoom}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker>
          </Map>
        );
      }
    }
    return res;
  }

  renderLocation() {
    var map = (
      <Col md={12} className={"bg-white"}>
        <br />
        <div className={"alert alert-warning text-center"}>
          <br />
          <p>
            <b>No hay datos de ubicación.</b>
          </p>
        </div>
        <br />
      </Col>
    );
    var location = get(this.state.good, "location", null);
    if (location) {
      var address = null;
      if (location.g_address) {
        address = (
          <p>
            <b>Dirección: </b>
            {location.g_address}{" "}
            {location.g_locality ? `- ${location.g_locality} ` : ""}
            {location.g_province ? `- ${location.g_province}` : ""}
          </p>
        );
      } else if (location.a_address) {
        address = (
          <p>
            <b>Dirección: </b>
            {location.a_address}{" "}
            {location.a_locality ? `- ${location.a_locality} ` : ""}
            {location.a_province ? `- ${location.a_province}` : ""}
          </p>
        );
      }
      var depot_detail = null;
      if (location.g_depot_detail) {
        depot_detail = (
          <p>
            <b>Exhibición: </b>
            {location.g_depot_detail}
          </p>
        );
      } else if (location.a_depot_detail) {
        depot_detail = (
          <p>
            <b>Exhibición: </b>
            {location.a_depot_detail}
          </p>
        );
      }
      var g_coords =
        get(location, "g_lat", false) && get(location, "g_lng", false);
      var a_coords =
        get(location, "a_lat", false) && get(location, "a_lng", false);
      var terrain = location.map.length > 0;
      var col1 = null;
      var col2 = null;
      if (g_coords || a_coords || terrain) {
        col1 = (
          <Col md={address || depot_detail ? 6 : 12}>
            <br />
            {this.renderMap()}
            <br />
          </Col>
        );
      }
      if (address || depot_detail) {
        col2 = (
          <Col md={g_coords || a_coords || terrain ? 6 : 12}>
            <br />
            {address}
            {depot_detail}
            <br />
          </Col>
        );
      }
      if (col1 || col2) {
        map = (
          <Row className={"bg-white"}>
            {col1}
            {col2}
          </Row>
        );
      }
    }
    return map;
  }

  renderSeeInGoogleMapsBtn(){
    let location = get(this.state.good, "location", null);
    let res = null;
    let loc = null;

    if(location && location.g_lat && location.g_lng){
      loc = "https://www.google.com/maps/search/?api=1&query=" + (location.g_lat)+","+(location.g_lng);
    }else if(location && location.a_lat && location.a_lng){
      loc = "https://www.google.com/maps/search/?api=1&query=" + (location.a_lat)+","+(location.a_lng);
    }else if(location && location.g_address){
      loc = "https://www.google.com/maps/search/?api=1&query=" + (location.g_address.replaceAll(" ", "+"))+ (location.g_locality.replaceAll(" ", "+"))+ (location.g_province.replaceAll(" "+ "+"));
    } else if(location && location.a_address) {
      loc = "https://www.google.com/maps/search/?api=1&query=" + (location.a_address.replaceAll(" ", "+"))+ (location.a_locality.replaceAll(" ", "+"))+ (location.a_province.replaceAll(" "+ "+"));
    }

    if(loc){
      res = <a href={`${loc}`}
              target="_blank"
              className="btn btn-sm btn-success">
                Ver en google maps
            </a>;
    }

    return res;
  }

  renderQuestions() {
    var questions = [];
    var form = null;
    if (this.state.user.username && this.state.user.role === "AP") {
      var classn =
        this.state.questions && this.state.questions.length % 2 === 0
          ? "bg-white"
          : "bg-sbackground";
      form = (
        <Row className={classn} key={"QForm"}>
          <Col md={"2"} className={"d-none d-sm-block"}>
            <br />
            <b className={"text"}>
              {as_datetime(
                new Date(getNow(this.context.date, this.context.localTime)),
                this.q_date_opts
              )}
            </b>
          </Col>
          <Col xs={"4"} md={"2"}>
            <br />
            <b className={"text text-subastas"}>{this.state.user.username}</b>
          </Col>
          <Col md={"6"}>
            <br />
            <input
              type={"text"}
              placeholder={"Pregunta"}
              id={"questionForm"}
              className={"form-control"}
            />
          </Col>
          <Col md={"2"}>
            <br />
            <button
              className={"btn btn-success text-white"}
              style={{ bottom: "1vh" }}
              onClick={this.createQuestion}
            >
              Preguntar
            </button>
          </Col>
        </Row>
      );
    }
    if (this.state.questions && this.state.questions.length > 0) {
      for (var i in this.state.questions) {
        var question = this.state.questions[i];
        var bg = i % 2 === 0 ? "white" : "sbackground";
        if (question) {
          questions.push(
            <Row className={`bg-${bg}`} key={`q_${i}`}>
              <Col
                xs={"2"}
                className={"d-none d-lg-block"}
                style={{ marginTop: "1vh", marginBottom: "1vh" }}
              >
                <b className={"text-subastas"}>
                  {as_datetime(question.q_date, this.q_date_opts)}
                </b>
              </Col>
              <Col
                xs={"4"}
                sm={"2"}
                style={{ marginTop: "1vh", marginBottom: "1vh" }}
              >
                <b className={"text text-subastas"}> {question.user} </b>
              </Col>
              <Col xs={"8"} style={{ marginTop: "1vh", marginBottom: "1vh" }}>
                <b
                  className={`text text-${
                    question.hidden ? "danger" : "subastas"
                  }`}
                >
                  {question.question}
                </b>
              </Col>
              {/* <Col xs={12} >
                      <UnameReport username={question.user} alignment={'left'} theme={'link'} section={'#questions'} {...this.props} />
                  </Col> */}
              <Col
                xs={"2"}
                className={"d-none d-lg-block"}
                style={{ marginTop: "1vh", marginBottom: "1vh" }}
              >
                <b className={"text text"}>
                  {" "}
                  {as_datetime(question.a_date, this.q_date_opts)}{" "}
                </b>
              </Col>
              <Col
                xs={"4"}
                sm={"2"}
                style={{ marginTop: "1vh", marginBottom: "1vh" }}
              >
                <b>{question.auctioneer}</b>
              </Col>
              <Col xs={"8"} style={{ marginTop: "1vh", marginBottom: "1vh" }}>
                <b>{question.answer}</b>
              </Col>
            </Row>
          );
        }
      }
    } else if (this.state.questions && this.state.questions.length === 0) {
      questions = (
        <Col md={12} className={"bg-white text-center"} key={"noQuestions"}>
          <br />
          <div className={"alert alert-warning text-center"}>
            <br />
            <p>
              <b>No hay preguntas.</b>
            </p>
          </div>
          <br />
        </Col>
      );
    } else {
      questions = (
        <Col md={12} className={"bg-white text-center"} key={"noQuestions"}>
          <br />
          <div className={"text-center"}>
            <br />
            <i className={"fa fa-spinner fa-spin fa-4x m-5"} />
          </div>
          <br />
        </Col>
      );
    }
    return [questions, form];
  }

  renderOffers() {
    var offers1 = [];
    var offers2 = [];
    var good = this.state.good;
    var offers = get(this.state.good, "offers", false);
    if (offers && offers.length > 0) {
      for (var i in good.offers) {
        var offer = good.offers[i];
        var bg = i % 2 === 0 ? "white" : "sbackground";
        if (i <= 2) {
          offers1.push(
            <Row className={`bg-${bg} mt-1`} key={`offer_${offer.offer}`}>
              <Col md={"4"} className={"text-center"}>
                <h5 className={"text text-subastas"}>
                  <b>{offer.user}</b>
                </h5>
              </Col>
              <Col md={"4"} className={"text-center"}>
                <h5 className={"text"}>
                  <b>{as_currency(offer.offer, good.currency.code)}</b>
                </h5>
              </Col>
              <Col md={"4"} className={"text-center"}>
                <h5 className={"text"}>
                  <b>{as_datetime(offer.date, this.g_date_opts)}</b>
                </h5>
              </Col>
            </Row>
          );
        } else {
          offers2.push(
            <Row className={`bg-${bg} mt-1`} key={`offer_${offer.offer}`}>
              <Col md={"4"} className={"text-center"}>
                <b className={"text text-subastas"}>{offer.user}</b>
              </Col>
              <Col md={"4"} className={"text-center"}>
                <b>{as_currency(offer.offer, good.currency.code)}</b>
              </Col>
              <Col md={"4"} className={"text-center"}>
                <b>{as_datetime(offer.date, this.g_date_opts)}</b>
              </Col>
            </Row>
          );
        }
      }
      var update_btn = null;
      if (this.state.new_offers) {
        update_btn = (
          <a
            className={`btn btn-sm ${
              this.state.new_offers
                ? `btn-info`
                : this.state.show_offers && !this.state.next_offers
                ? "btn-danger"
                : "btn-success"
            } text-white`}
            onClick={this.offerActionBtn}
            id={"more_offers_btn"}
          >
            actualizar ofertas
          </a>
        );
      }
      var more = null;
      if (offers2.length > 0 || this.state.next_offers) {
        if (this.hide_mo_btn) {
          more = <i className={"fa fa-spinner fa-spin"} />;
        } else {
          more = (
            <a
              className={`btn btn-sm ${
                this.state.show_offers && !this.state.next_offers
                  ? "btn-danger"
                  : "btn-success"
              } text-white text-center`}
              onClick={this.offerActionBtn}
              id={"more_offers_btn"}
            >
              {`Ver ${
                this.state.show_offers && !this.state.next_offers
                  ? "menos"
                  : "mas"
              } ofertas`}
            </a>
          );
        }
      }
      offers1.push(
        <Row className={`bg-sbackground`} key={`offer_more`}>
          <Col md={"12"} className={"text-center"}>
            <Collapse isOpen={this.state.show_offers}>{offers2}</Collapse>
            {more}
          </Col>
        </Row>
      );
    } else if (offers && offers.length === 0) {
      offers1 = (
        <div className={"alert alert-warning text-center"}>
          <br />
          <p>
            <b>No hay ofertas.</b>
          </p>
        </div>
      );
    } else {
      offers1 = (
        <div className={"text-center"}>
          <br />
          <i className={"fa fa-spinner fa-spin fa-4x m-5"} />
        </div>
      );
    }
    return (
      <Row>
        <Col md={"12"} className={"bg-white p-3 text-left"}>
          <Row>
            <Col md={"4"}>
              <UnameReport section={"#offers"} {...this.props} />
            </Col>
            <Col md={"4"} className={"text-center"}>
              {update_btn}
            </Col>
          </Row>
          {offers1}
          <Col md={"4"}>
            <UnameReport section={"#offers"} {...this.props} />
          </Col>
        </Col>
      </Row>
    );
  }

  renderStreamOffers() {
    var res = null;
    var good = this.state.good;
    var offers = get(good, "offers", false);
    if (
      good.stream &&
      Object.keys(good.stream).length !== 0 &&
      this.imThisAuct()
    ) {
      if (offers && offers.length > 0) {
        var offers = [];
        for (var i in good.offers) {
          var offer = good.offers[i];
          var bg = i % 2 === 0 ? "white" : "sbackground";
          offers.push(
            <Row className={`bg-${bg} mt-1`} key={`offer_${offer.offer}`}>
              <Col md={"4"} className={"text-center"}>
                <b className={"text text-subastas"}>{offer.first_name}</b>
                <br />
                <small>
                  <b className={"text text-subastas"}>({offer.user})</b>
                </small>
              </Col>
              <Col md={"4"} className={"text-center"}>
                <b>{as_currency(offer.offer, good.currency.code)}</b>
              </Col>
              <Col md={"4"} className={"text-center"}>
                <b>{as_datetime(offer.date, this.so_date_opts)}</b>
              </Col>
            </Row>
          );
        }
      }
      res = (
        <Col md={"12"} className={"bg-white text-center m-1"}>
          {offers}
        </Col>
      );
    }
    return res;
  }

  renderCosts() {
    var spinner = <i className={"fa fa-spinner fa-spin"} />;
    var good = this.state.good;
    var end_date = new Date(good.end_date);
    var ended =
      new Date(getNow(this.context.date, this.context.localTime)) > end_date;
    var one_hour_bfe = new Date(good.end_date);
    one_hour_bfe.setHours(one_hour_bfe.getHours() - 1);
    var last_hour =
      !ended &&
      new Date(getNow(this.context.date, this.context.localTime)) >
        one_hour_bfe;
    good.iva = parseFloat(good.iva);
    good.base_price = parseFloat(good.base_price);
    var title = "Composición de la próxima oferta";
    if (ended) {
      title = "Composición de la última oferta";
    }
    var rows = [
      <tr key={"fill1"} />,
      <tr md={12} className={"text-center"} key={"spinner"}>
        <td>
          <i className={"fa fa-spinner fa-spin fa-4x m-5"} />
        </td>
      </tr>,
      <tr key={"fill2"} />,
    ];
    if (get(good, "name", false)) {
      var costs = calcNextCosts(good);
      rows = [];
      rows.push(
        <tr key={"offer"}>
          <td>
            <small>Oferta</small>
          </td>
          <td>
            <small>{as_currency(costs.value, good.currency.code)}</small>
          </td>
        </tr>
      );
      if (costs.sellos_amount) {
        rows.push(
          <tr key={"sellos"}>
            <td>
              <small>Impuesto sellos</small>
            </td>
            <td>
              <small>
                {as_currency(costs.sellos_amount, good.currency.code)}
              </small>
            </td>
          </tr>
        );
      }
      if (costs.iva_amount) {
        rows.push(
          <tr key={"iva"}>
            <td>
              <small>I.V.A.</small>
            </td>
            <td>
              <small>{as_currency(costs.iva_amount, good.currency.code)}</small>
            </td>
          </tr>
        );
      }
      for (var i in costs.taxes) {
        var tax_amount = costs.taxes[i];
        var amount = as_currency(tax_amount, good.currency.code);
        rows.push(
          <tr key={`tax_${i}`}>
            <td>
              <small>{i}</small>
            </td>
            <td>
              <small>{amount !== "NaN" ? amount : spinner}</small>
            </td>
          </tr>
        );
      }
    }
    let total = false;
    if (costs && costs.taxes) {
      total = Object.keys(costs.taxes).includes("Costo medio de pago");
    }
    var res = (
      <>
        <div className={"bg-white m-1"}>
          <br />
          <div className={"text-center"}>
            {last_hour ? (
              <a
                onClick={() =>
                  this.setState({ hide_costs: !this.state.hide_costs })
                }
                href={"#costs_table"}
              >
                <b>
                  {title}{" "}
                  <i
                    className={`fa fa-angle-${
                      this.state.hide_costs ? "down" : "up"
                    }`}
                  />
                </b>
              </a>
            ) : (
              <b>{title}</b>
            )}
          </div>
          <br />
          {/* <Collapse isOpen={last_hour ? this.state.hide_costs : true}> */}
          <table className={"table table-striped mb-0"}>
            <tbody hidden={last_hour ? this.state.hide_costs : false}>
              {rows}
            </tbody>
            <tfoot>
              <tr>
                <th>
                  <a
                    href={reverse("como_pagar")}
                    target="_blank"
                    id={"subtotal"}
                  >
                    {total ? (
                      "Total a pagar"
                    ) : (
                      <div>
                        Subtotal a pagar
                        <br />
                        <small>(No incluye costo medio de pago)</small>
                      </div>
                    )}
                  </a>
                  <UncontrolledPopover
                    trigger="hover"
                    placement="bottom"
                    target={`subtotal`}
                  >
                    <PopoverBody>
                      <p
                        className={`text text-justify text-${
                          this.props.sources[this.props.site].theme_name
                        }`}
                      >
                        <b>Ver + info sobre pagos.</b>
                      </p>
                    </PopoverBody>
                  </UncontrolledPopover>
                </th>
                <th>
                  {get(good, "name", false)
                    ? as_currency(costs.total, good.currency.code)
                    : spinner}
                </th>
              </tr>
            </tfoot>
          </table>
          {/* </Collapse> */}
        </div>
      </>
    );
    var cond0 = good.status === "Presencial";
    var cond1 = good.status === "Licitacion";
    var cond2 =
      good.stream && Object.keys(good.stream).length !== 0 && this.imThisAuct();
    if (cond0 || cond1 || cond2) {
      res = null;
    }
    return res;
  }

  renderImgs() {
    var photos = get(this.state.good, "photos", []);
    var imgs = [];
    var img_style = {
      width: "100%",
    };
    var good = this.state.good;
    var disabled =
      navigator.userAgent.toLowerCase().indexOf("firefox") > -1 ||
      good.end_date === null;
    var end_date = new Date(good.end_date);
    var sold_conds =
      addMinutes(end_date, 10) <
        new Date(getNow(this.context.date, this.context.localTime)) &&
      good.last_oferent !== "";
    var desierto_conds =
      new Date(good.end_date) <
        new Date(getNow(this.context.date, this.context.localTime)) &&
      good.last_oferent === "";
    var sign_style = {
      position: "absolute",
      width: "-webkit-fill-available",
      height: "-webkit-fill-available",
    };
    var floatingStyle = {
      position: "absolute",
      top: "5px",
      right: "10px",
    };
    var sign = null;
    if (good.suspension && !disabled) {
      sign = <img src={Suspended} style={sign_style} />;
    } else if (sold_conds && !disabled) {
      sign = <img src={SoldUpper} style={sign_style} />;
    } else if (desierto_conds && !disabled) {
      sign = <img src={Desierto} style={sign_style} />;
    }
    var live = null;
    if (good.stream && Object.keys(good.stream).length !== 0) {
      live = (
        <Badge color={"danger"} style={floatingStyle}>
          Se transmitirá en vivo.
        </Badge>
      );
    }
    if (photos.length > 0) {
      for (var i in photos) {
        var photo = photos[i];
        imgs.push(
          <Col
            key={`${photo.url}-${i}`}
            md={i === "0" ? "12" : "3"}
            xs={"4"}
            style={{
              paddingLeft: "5px",
              paddingRight: "5px",
              paddingTop: "5px",
              paddingBottom: "0px",
            }}
            data-toggle={"tooltip"}
            title={"Click para ampliar"}
          >
            {i === "0" ? (
              <div style={{ position: "relative" }}>
                <Zoom>
                  {sign}
                  {live}
                  <img
                    src={photo.url}
                    className={"img img-responsive"}
                    style={img_style}
                  />
                </Zoom>
              </div>
            ) : (
              <Zoom>
                <img
                  src={photo.url}
                  className={"img img-responsive"}
                  style={img_style}
                />
              </Zoom>
            )}
          </Col>
        );
      }
    } else {
      imgs.push(
        <Col
          key={"default"}
          md={"12"}
          style={{
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingTop: "5px",
            paddingBottom: "0px",
          }}
          data-toggle={"tooltip"}
          title={"Click para ampliar"}
        >
          <img
            src={this.props.sources[this.props.site].defaultImg}
            className={"img img-responsive"}
            style={img_style}
          />
        </Col>
      );
    }
    var stream = null;
    var stream_style = null;
    if (window.innerHeight >= window.innerWidth) {
      stream_style = { height: "45vw", width: "80vw", maxWidth: "inherit" };
    } else {
      stream_style = { height: "18vw", width: "32vw", maxWidth: "inherit" };
    }

    if (good.stream) {
      var st_cond =
        new Date(getNow(this.context.date, this.context.localTime)) >
          new Date(good.stream.start) &&
        new Date(getNow(this.context.date, this.context.localTime)) <
          new Date(good.stream.end);
      if (st_cond) {
        stream = (
          <Col key={"stream"} md={"12"}>
            <iframe
              id="player"
              type="text/html"
              src={good.stream.url}
              frameBorder="0"
              allowFullScreen
              style={stream_style}
            />
          </Col>
        );
      }
    }
    return (
      <Row className={"bg-white text-center mx-1"}>
        {stream}
        {imgs}
      </Row>
    );
  }

  renderHeader(started, finished) {
    var good = this.state.good;
    var spinner = <i className={"fa fa-spinner fa-spin"}> </i>;
    var state = spinner;
    var timer = spinner;

    var timer_btn = (
      <h4 id={"timer"} className={"btn btn-lg btn-outline-danger"}>
        {timer}
      </h4>
    );
    if (get(good, "name", false)) {
      if (this.state.ws_error) {
        state = (
          <b className={"text text-danger"}>
            Desconectado de Notificaciones automáticas
          </b>
        );
        timer_btn = (
          <button
            className={"btn btn-success text-white"}
            onClick={() => {
              window.location.reload();
            }}
          >
            Actualizar
          </button>
        );
      } else if (good.status === "Presencial" || good.status === "Licitacion") {
        if (!started) {
          state = (
            <b>
              Empieza el:
              <br /> {as_datetime(good.start_date)}
            </b>
          );
          timer = (
            <Timer
              end_date={new Date(good.start_date)}
              callback={null}
              view={false}
              sourceView={"detail"}
            />
          );
          timer_btn = <h4 id={"timer"}>{timer}</h4>;
        } else {
          state = (
            <b>
              Fue el:
              <br /> {as_datetime(good.start_date)}
            </b>
          );
          timer_btn = null;
        }
      } else if (started && !finished) {
        timer = (
          <Timer
            end_date={new Date(good.end_date)}
            view={false}
            callback={() => setTimeout(this.getGoodData, 5000)}
            sourceView={"detail"}
          />
        );
        timer_btn = <h4 id={"timer"}>{timer}</h4>;
        state = (
          <b>
            Termina el:
            <br /> {as_datetime(good.end_date)}
          </b>
        );
      } else if (!finished) {
        timer = (
          <Timer
            end_date={new Date(good.start_date)}
            callback={() => this.getGoodData}
            view={false}
            sourceView={"detail"}
          />
        );
        timer_btn = <h4 id={"timer"}>{timer}</h4>;
        state = (
          <b>
            Empieza el:
            <br /> {as_datetime(good.start_date)}
          </b>
        );
      } else if (finished) {
        timer = null;
        timer_btn = null;
        state = (
          <b>
            Terminó el:
            <br /> {as_datetime(good.end_date)}
          </b>
        );
      }
    }
    if (good.suspension) {
      timer = null;
      timer_btn = null;
      state = null;
    }
    return (
      <div className={"bg-white text-center xs-pull-left m-1"}>
        <h3>
          <b>{get(good, "name", false) ? good.name : spinner}</b>
        </h3>
        {/*<div dangerouslySetInnerHTML={{__html: good.description}}/><br/>*/}
        <h3>
          {good.status === "Presencial" ? "Base:" : ""}{" "}
          {get(good, "name", false)
            ? as_currency(good.price, good.currency.code)
            : spinner}
        </h3>
        <h5 className={"text"}>{state}</h5>
        {timer_btn}
        <UncontrolledPopover trigger="hover" placement="left" target={`timer`}>
          <PopoverBody>
            <b>Fecha inicio:</b>
            <br />
            {as_datetime(good.start_date, this.g_date_opts)}
            <br />
            <br />
            {good.end_date ? (
              <>
                <b>Fecha fin:</b>
                <br />
                {as_datetime(good.end_date, this.g_date_opts)}
              </>
            ) : (
              <></>
            )}
          </PopoverBody>
        </UncontrolledPopover>
      </div>
    );
  }

  renderLawMinuteTag() {
    var good = this.state.good;
    var res = null;
    var stream_btn = null;
    // var session = Session.getSession()[this.props.source];
    // var doc = session.doc;
    // var public_doc = session.public_doc;
    if (good.auctioneer) {
      // var cond1 = doc === good.auctioneer.doc;
      // var cond2 = public_doc === good.auctioneer.public_doc;
      var cond3 =
        new Date(good.end_date) >
        new Date(getNow(this.context.date, this.context.localTime));
      if (this.imThisAuct() && cond3) {
        stream_btn = (
          <div
            className={"btn btn-outline-danger btn-sm mx-1"}
            onClick={() => (this.hide_stream_conf = !this.hide_stream_conf)}
          >
            Configurar trasnmisión en vivo
          </div>
        );
      }
    }
    if (good != {}) {
      var law_minute = null;
      if (good.law_minute) {
        res = (
          <a href={"#"} className={"col-md-12"}>
            <Badge color={"success"} style={{}} id={`law_${good.id}`}>
              Minuto de ley
            </Badge>
            <UncontrolledPopover
              trigger="hover"
              placement="left"
              target={`law_${good.id}`}
            >
              <PopoverBody>
                <p className={"text text-justify"}>
                  <b>
                    El minuto de ley preve que una vez realizada una oferta
                    dentro del último minuto, habrá un minuto adicional para
                    posibles nuevas ofertas. El minuto de ley se activa cuantas
                    veces haya una oferta en el último minuto y hasta que no
                    haya más ofertas por un minuto.
                  </b>
                </p>

                <p>
                  <b>Ejemplo:</b>
                </p>
                <p>
                  <b>Cierre:</b> 20:30hs
                </p>

                <p>
                  <b>Oferta A:</b> 20:29:45hs se extiende a {/*20:30:45hs*/}{" "}
                  20:31:00hs
                </p>
                <p className={"text text-justify"}>
                  Si dentro del minuto comprendido entre
                  {/*20:30:45hs y 20:30:45hs*/} 20:30:00hs y 20:31:00hs se
                  realiza una nueva oferta, se extenderá 1 minuto más a partir
                  de la fecha fin.
                </p>
                <p>
                  <b>Oferta B:</b> 20:30:41hs se extiende a {/*20:31:41hs*/}{" "}
                  20:32:00hs
                </p>
                <p className={"text text-justify"}>
                  Si dentro del minuto comprendido entre{" "}
                  {/*20:30:41hs y 20:31:41hs*/} 20:31:00hs y 20:32:00hs no se
                  realizaran nuevas ofertas, la oferta B surgirá ganadora.
                </p>
              </PopoverBody>
            </UncontrolledPopover>
            {this.state.good.warranty ? (
              <>
                <Badge color={"danger"} style={{}} id={`warranty_${good.id}`}>
                  Garantía de oferta
                </Badge>
                <UncontrolledPopover
                  trigger="hover"
                  placement="left"
                  target={`warranty_${good.id}`}
                >
                  <PopoverBody>
                    <p className={"text text-justify"}>
                      <b>
                        Para poder participar de esta subasta, se debe realizar
                        un depósito de{" "}
                        {as_currency(good.warranty, good.currency.code)}
                      </b>
                    </p>
                    {new Date(
                      getNow(this.context.date, this.context.localTime)
                    ) < new Date(good.due_warranty) ? (
                      <p className={"text text-success text-justify"}>
                        <b>
                          La fecha límite para realizar el depósito es{" "}
                          {as_datetime(good.due_warranty)}
                        </b>
                      </p>
                    ) : (
                      <p className={"text text-danger"}>
                        <b>
                          Ya no se puede crear un depósito en garantía. Se
                          alcanzó la fecha límite.
                        </b>
                      </p>
                    )}
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            ) : null}
            {stream_btn}
          </a>
        );
      }
    }
    return res;
  }

  renderBestBidder() {
    var good = this.state.good;
    var end_date = new Date(good.end_date);
    var ended =
      new Date(getNow(this.context.date, this.context.localTime)) > end_date;
    // var one_hour_bfe = new Date(good.end_date);
    // one_hour_bfe.setHours(one_hour_bfe.getHours() - 1);
    // var last_hour = !ended && new Date() > one_hour_bfe;
    var res = null;
    if (
      good != {} &&
      !(
        good.stream &&
        Object.keys(good.stream).length !== 0 &&
        this.imThisAuct()
      ) &&
      good.last_oferent &&
      !ended
    ) {
      res = (
        <div className={"bg-white m-1 text-center"}>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <h6 className={"pull-left m-2"}>
                Mejor postor:
                <br />
              </h6>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <h4
                className={`text text-center text-${
                  this.props.sources[this.props.site].theme_name
                }`}
              >
                {good.last_oferent}
              </h4>
              {/* <UnameReport username={good.last_oferent} {...this.props}/> */}
            </Col>
          </Row>
        </div>
      );
    }
    return res;
  }

  renderPercent() {
    var good = this.state.good;
    var res = null;
    var cond0 = good != {};
    var cond1 = good.percent;
    var cond2 = !(
      good.stream &&
      Object.keys(good.stream).length !== 0 &&
      this.imThisAuct()
    );
    var cond3 = good.status !== "Presencial";
    var cond4 = good.status !== "Licitacion";
    if (cond0 && cond1 && cond2 && cond3 && cond4) {
      res = (
        <div className={"bg-white m-1"}>
          <p className={"text text-danger py-2 my-2"}>
            <b>
              Porcentaje a abonar: <br />
              {`${parseInt(
                good.percent
              )}% del valor + comisiones + costo medio de pago`}
            </b>
          </p>
        </div>
      );
    }
    return res;
  }

  renderAcceptance() {
    var good = this.state.good;
    var res = null;
    if (good != {} && good.acceptance) {
      res = (
        <div className={"bg-white m-1"}>
          <p className={"text text-danger"}>
            <b>Sujeto a aprobación del vendedor:</b> <br />
            El vendedor debe aceptar la última oferta para efectivizar la
            subasta.
          </p>
        </div>
      );
    }
    return res;
  }

  renderOrigin() {
    var res = null;
    var good = this.state.good;
    var glink = "";
    if (good.name) {
      var url_args = [this.props.source, good.id, slugify(good.name)];
      glink = reverse(
        "good_view",
        this.props.source,
        url_args,
        {},
        this.props.sources
      );
    }
    if (this.props.source !== "privadas") {
      res = (
        <div className={"bg-white text-center"}>
          <a href={glink}>
            <h3>Ver subasta en: </h3>
            <img
              src={this.props.sources[this.props.source].logo}
              className={"img img-responsive"}
              style={{ width: "90%" }}
            />
          </a>
          <br />
          <br />
        </div>
      );
    }
    return res;
  }

  renderVideo() {
    var res = (
      <Col md={12} className={"bg-white text-center"} key={"noVideo"}>
        <br />
        <div className={"alert alert-warning text-center"}>
          <br />
          <p>
            <b>No hay video.</b>
          </p>
        </div>
        <br />
      </Col>
    );
    var good = this.state.good;
    var video = false;
    if (good.video !== null && good.video !== "") {
      video = good.video;
    }
    var video_url = false;
    if (good.video_url !== null && good.video_url !== "") {
      video_url = good.video_url;
    }
    if (video_url && video_url.includes("youtube")) {
      var video_id = video_url.split("v=")[1];
      var ampersandPosition = video_id.indexOf("&");
      if (ampersandPosition != -1) {
        video_id = video_id.substring(0, ampersandPosition);
      }
      res = (
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            className="embed-responsive-item"
            src={`https://youtube.com/embed/${video_id}`}
            allowFullScreen
          />
        </div>
      );
    } else if (video) {
      res = (
        <Col className={"text-center embed-responsive embed-responsive-16by9"}>
          {/* <a className={`btn btn-${this.props.sources[this.props.site].theme_name}`} href={good.video}>Descargar video</a> */}
          <video className={"p-1 embed-responsive-item"} controls>
            <source src={good.video} type="video/mp4" />
            Tu navegador no puede reproducir este video, intenta actualizarlo.
          </video>
        </Col>
      );
    }
    return res;
  }

  renderDecree() {
    var res = null;
    if (this.state.show_decree_act) {
      if (this.state.decree) {
        var background = `bg-${this.props.sources[this.props.site].theme_name}`;
        var acts = [];
        for (var i in this.state.decree.acts) {
          var act = this.state.decree.acts[i];
          acts.push(
            <>
              {act.texto ? (
                <p dangerouslySetInnerHTML={{ __html: `${act.texto}` }} />
              ) : null}
              {act.archivo ? (
                <p>
                  <a className={"btn btn-success btn-sm"} href={act.archivo}>
                    {`Descargar acta ${i}: ${act.nombre}`}
                  </a>
                </p>
              ) : null}
            </>
          );
        }
        res = (
          <div className={"m-3"}>
            {this.state.decree.text || this.state.decree.file ? (
              <Card className={"mb-2"}>
                <CardHeader
                  className={background}
                  style={{ height: "8vh", maxHeight: "75px" }}
                >
                  <CardTitle className={"text text-left text-white"}>
                    Decreto{" "}
                    {this.props.site !== "privadas"
                      ? this.state.decree.expediente
                      : this.state.decree.caratula}
                  </CardTitle>
                  <div
                    className="btn btn-sm btn-primary pull-right"
                    onClick={() =>
                      this.setState({ show_decree: !this.state.show_decree })
                    }
                    style={{ bottom: "55px" }}
                  >
                    {this.state.show_decree ? "Ocultar" : "Mostrar"}
                  </div>
                </CardHeader>
                <Collapse
                  className={"text-center"}
                  isOpen={this.state.show_decree}
                >
                  <CardBody className={"bg-lighter"}>
                    {this.state.decree.text ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: `${this.state.decree.text}`,
                        }}
                      />
                    ) : null}
                    {this.state.decree.file ? (
                      <a
                        className={"btn btn-success btn-sm text-white"}
                        href={this.state.decree.file.url}
                      >
                        {`Descargar Archivo:
                                            ${this.state.decree.file.name
                                              .split(".")[0]
                                              .replace("decretos/", "")}`}
                      </a>
                    ) : null}
                  </CardBody>
                </Collapse>
              </Card>
            ) : (
              <div className={"alert alert-danger"}>
                No hay decreto para mostrar
              </div>
            )}
            {this.state.decree.acts.length > 0 ? (
              <Card className={"mb-2"}>
                <CardHeader
                  className={background}
                  style={{ height: "8vh", maxHeight: "75px" }}
                >
                  <CardTitle className={"text text-left text-white"}>
                    Actas
                  </CardTitle>
                  <div
                    className="btn btn-sm btn-primary pull-right"
                    onClick={() =>
                      this.setState({ show_acts: !this.state.show_acts })
                    }
                    style={{ bottom: "55px" }}
                  >
                    {this.state.show_acts ? "Ocultar" : "Mostrar"}
                  </div>
                </CardHeader>
                <Collapse
                  className={"text-center"}
                  isOpen={this.state.show_acts}
                >
                  <CardBody className={"bg-lighter"}>{acts}</CardBody>
                </Collapse>
              </Card>
            ) : (
              <div className={"alert alert-danger"}>
                No hay actas para mostrar
              </div>
            )}
          </div>
        );
      } else {
        res = <i className={"fa fa-spinner fa-spin fa-5x m-5"}> </i>;
        this.getDecree();
      }
    }
    return res;
  }

  toggleRoom() {
    var in_room =
      this.state.rooms[this.props.source] &&
      this.state.rooms[this.props.source].includes(parseInt(this.props.gid));
    if (in_room) {
      this.removeFromRoom(parseInt(this.props.gid), this.props.source);
    } else {
      this.addToRoom(parseInt(this.props.gid), this.props.source);
    }
    // this.setState({in_room:!this.state.in_room});
  }

  renderRoomBtns() {
    var res = null;
    var session = Session.getSession();
    var username = session[this.props.source].username;
    if (Session.loggedIn() && username) {
      var in_room =
        this.state.rooms[this.props.source] &&
        this.state.rooms[this.props.source].includes(parseInt(this.props.gid));
      res = (
        <Row>
          <Col xs={7}>
            <Button
              className={`btn btn-sm btn-${
                in_room
                  ? "danger"
                  : this.props.sources[this.props.site].theme_name
              }`}
              style={{ width: "-webkit-fill-available" }}
              onClick={this.toggleRoom}
            >
              {in_room ? "Quitar de mi sala" : "Agregar a mi sala"}
            </Button>
          </Col>
          <Col xs={5}>
            <Button
              className={`btn btn-sm btn-${
                this.props.sources[this.props.site].theme_name
              }`}
              style={{ width: "-webkit-fill-available" }}
              onClick={() => (window.location = reverse("mi_sala"))}
            >
              Ir a mi sala
            </Button>
          </Col>
        </Row>
      );
    }
    return res;
  }

  renderShareBtn() {
    let share = null;

    if (this.state.good.start_date && this.state.good.end_date) {
      let url = window.location.href;
      let startDate = new Date(this.state.good.start_date);
      let endDate = new Date(this.state.good.end_date);

      let sdate = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };

      let stime = {
        hour: "numeric",
        minute: "numeric",
      };

      let statusText = {
        "En subasta": `¡Mirá ya empezo la subasta!👀\n\nIngresá ahora, la subasta está en curso y termina el ${as_datetime(endDate, sdate)}\n\nRegistrate y ofertá :)\n` ,
        "Próximo": `¡Mirá esta subasta!👀\n\n¡Ingresá ahora, la subasta inicia el ${as_datetime(startDate, sdate)}\n\nRegistrate y ofertá\n`,
        "Termina mañana": `😱¡Mirá esta subasta que esta por finalizar!\n\nIngresá ahora, la subasta termina el ${as_datetime(endDate, sdate)} a las ${as_datetime(endDate, stime)}.\n¡Pocas horas para ofertar!\n\nRegistrate y ofertá\n`,
        "Empieza mañana": `¡Mirá esta subasta!👀\n\nEsta subasta comienza el ${as_datetime(startDate, sdate)} a las ${as_datetime(startDate, stime)}\n\nRegistrate y ofertá\n`,
        "Termina hoy": `⏰¡Mirá esta subasta que ya se termina!\n\nIngresá ahora, la subasta termina el día ${as_datetime(endDate, sdate)}\n\nRegistrate y ofertá\n`,
        "Empieza hoy": `¡Mirá esta subasta!👀\n\nIngresá ahora, la subasta inicia el día ${as_datetime(startDate,sdate)} y termina el día ${as_datetime(endDate,sdate)}\n\nRegistrate y ofertá\n`,
      }
      let statusMailSubject ={
        "En subasta": "¡Mirá ya empezo la subasta!👀",
        "Próximo": "¡Mirá esta subasta!👀",
        "Termina mañana": "😱¡Mirá esta subasta que esta por finalizar!",
        "Empieza mañana": "¡Mirá esta subasta!👀",
        "Termina hoy": "⏰¡Mirá esta subasta que ya se termina!",
        "Empieza hoy": "¡Mirá esta subasta!👀",
      }
      let statusMail={
        "En subasta": `Ingresá ahora, la subasta está en curso y termina el ${as_datetime(endDate, sdate)}\n\nRegistrate y ofertá :)\n` ,
        "Próximo": `¡Ingresá ahora, la subasta inicia el ${as_datetime(startDate,sdate)}\n\nRegistrate y ofertá\n`,
        "Termina mañana": `Ingresá ahora, la subasta termina el ${as_datetime(endDate,sdate)} a las ${as_datetime(endDate,stime)}.\n¡Pocas horas para ofertar!\n\nRegistrate y ofertá\n`,
        "Empieza mañana": `Esta subasta comienza el ${as_datetime(startDate,sdate)} a las ${as_datetime(startDate,stime)}\n\nRegistrate y ofertá\n`,
        "Termina hoy": `Ingresá ahora, la subasta termina el día ${as_datetime(endDate,sdate)}\n\nRegistrate y ofertá\n`,
        "Empieza hoy": `Ingresá ahora, la subasta inicia el día ${as_datetime(startDate,sdate)} y termina el día ${as_datetime(endDate,sdate)}\n\nRegistrate y ofertá\n`
      }

      share = (
        <>
          <Row className={`${styles["share-block"]}`}>
            <Col lg={7} md={12} sm={7} xs={12}>
              <div className="wrapper-adtb">
                <SaveInCalendar
                  good={this.state.good}
                  className={"wrapper-adtb"}
                />
              </div>
            </Col>
            <Col lg={5} md={12} sm={5} xs={12}>
              <UncontrolledDropdown>
                <DropdownToggle
                  caret
                  size="sm"
                  className={`${
                    styles["buttons-size"] + " " + styles["share"]
                  }`}
                >
                  {"Compartir "}
                </DropdownToggle>
                <DropdownMenu className={`text-center dropdow-share`}>
                  <WhatsappShareButton
                    url={window.location.href}
                    title={statusText[this.state.good.status]}
                    className={"p-1"}
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <FacebookShareButton
                    url={window.location.href}
                    quote={statusText[this.state.good.status]}
                    hashtag={"#esubasta"}
                    className={"p-1"}
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={window.location.href}
                    via={"esubasta"}
                    title={statusText[this.state.good.status]}
                    hashtags={[
                      "esubasta",
                      "subastaonline",
                      "subastajudicial",
                      "remate",
                      "remateonline",
                      "remates",
                      "subastaselectronicas",
                    ]}
                    className={"p-1"}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <EmailShareButton
                    url={""}
                    subject={statusMailSubject[this.state.good.status]}
                    body={statusMail[this.state.good.status]}
                    className={"p-1"}
                  >
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                  <div className={`p-1`}>
                    <Button
                      onClick={() => copyLinkToClipBoard(url)}
                      data-toggle={"tooltip"}
                      title={" Copiar en el portapapeles"}
                      className={`btn-icon-only btn-default rounded-circle ${styles["copy-link"]}`}
                      id={"copy-link"}
                    >
                      <span className="tooltiptext" id="tooltip">
                        <i className="fa fa-clipboard" aria-hidden="true"></i>
                      </span>
                    </Button>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
        </>
      );
    }

    return share;
  }

  renderOfferBtn() {
    var good = this.state.good;
    var username = this.state.user.username;
    var can_offer =
      /*!this.userLastOffered() &&*/ Session.loggedIn() &&
      username &&
      !good.suspension &&
      this.state.offer_enabled;
    var started =
      new Date(good.start_date) <
      new Date(getNow(this.context.date, this.context.localTime));
    var finished =
      new Date(good.end_date) <
      new Date(getNow(this.context.date, this.context.localTime));
    var glink = "";
    let not_due = new Date(getNow(this.context.date, this.context.localTime)) < new Date(good.due_warranty)
    let exempt =
      this.state.exemptions &&
      this.state.exemptions.good_ids &&
      this.state.exemptions.good_ids.includes(this.state.good.id);
    let dep_needed =
      !this.state.deposit &&
      good.warranty &&
      good.warranty > 0 &&
      not_due;
    let dep_pending =
      not_due && ((this.state.deposit && this.state.deposit.status &&
      this.state.deposit.status.style !== "success") || this.state.deposit &&
      !this.state.deposit.link);
    let warranty_due =
      good.warranty &&
      good.warranty > 0 &&
      new Date(getNow(this.context.date, this.context.localTime)) >
        new Date(good.due_warranty);
    let war_offer = (!good.warranty || exempt || (this.state.deposit &&
                                        this.state.deposit.status &&
                                        this.state.deposit.status.style === "success"));
    let is_admin = Session.getRole() == 'AM';
    let is_bidder = Session.getRole() == 'AP';
    if (good.name) {
      var url_args = [this.props.source, good.id, slugify(good.name)];
      glink = reverse(
        "good_view",
        this.props.source,
        url_args,
        {},
        this.props.sources
      );
    }
    if (good.status === "Presencial") {
      var offer_btn = (
        <div>
          <a
            className={`btn btn-${
              this.props.sources[this.props.site].theme_name
            }`}
            style={{ width: "100%" }}
            id={"presential"}
            href={glink}
          >
            <h5 className={"text text-white mb-0"}>
              <b>Subasta Presencial</b>
            </h5>
          </a>
          {/*{popover}*/}
        </div>
      );
    } else if (good.status === "Licitacion") {
      var offer_btn = (
        <div>
          <a
            className={`btn btn-${
              this.props.sources[this.props.site].theme_name
            }`}
            style={{ width: "100%" }}
            id={"licitacion"}
            href={glink}
          >
            <h5 className={"text text-white mb-0"}>
              <b>Licitación</b>
            </h5>
          </a>
          {/*{popover}*/}
        </div>
      );
    } else if (
      (dep_pending && !exempt && is_bidder) ||
      (is_admin && (dep_pending || dep_needed || warranty_due))
    ) {
      let ref = reverse(
        "deposito_usuario",
        this.props.source,
        [],
        {},
        this.props.sources
      );
      if (this.state.deposit && this.state.deposit.link){
        ref = this.state.deposit.link;
      }
      if (is_admin) {
        ref = reverse(
          "deposito_admin",
          this.props.source,
          [],
          {},
          this.props.sources
        );
      }
      var offer_btn = (
        <a
          className={"btn btn-danger"}
          style={{ width: "100%" }}
          href={ref}
          id={"deposit"}
        >
          <h5 className={"text text-white mb-0"}>
            { this.state.deposit && this.state.deposit.link ?
            <b>Pagar garantía</b>
            :
            <b>Ver garantías</b>
            }
          </h5>
        </a>
      );
    } else if (dep_needed && !exempt && is_bidder) {
      var offer_btn = (
        <>
        <a
          className={`btn btn-${
            this.props.sources[this.props.site].theme_name
          }`}
          style={{ width: "100%" }}
          onClick={() => {
            if (!this.creating_deposit) {
              this.createDeposit();
            }
          }}
          id={"create_deposit"}
        >
          <h5
            className={"text text-white mb-0"}
            hidden={this.state.creating_deposit}
          >
            <b>Crear garantía de oferta</b>
          </h5>
          <i
            hidden={!this.state.creating_deposit}
            className={"fa fa-spinner fa-spin fa-4x text-white"}
          />
        </a>
        <div className={"text text-center m-1 bg-white"}>
          <b>
            Se debe realizar
            un depósito de{" "} {as_currency(good.warranty, good.currency.code)}
          </b>
          <br />
          <b className={"text text-danger text-center"}>
            La fecha límite para realizar el depósito es{" "}
            {as_datetime(good.due_warranty)}
          </b>
        </div>
        </>
      );
    } else if (warranty_due && !exempt && !war_offer) {
      var offer_btn = (
        <div className={"bg-white m-1"}>
          <p className={"text text-danger py-2 my-2"}>
            <b>
              Ya no se puede realizar el depósito requerido para participar de
              la subasta.
            </b>
          </p>
          <p className={"text text-danger py-2 my-2"}>
            <b>
              Fecha vencimiento: {as_datetime(this.state.good.due_warranty)}
            </b>
          </p>
        </div>
      );
    } else if (started && !finished && war_offer && Session.loggedIn(this.props.source)) {
      var offer_btn = (
        <div>
          <div
            className={
              can_offer && this.state.offer_enabled && started && !finished
                ? "btn btn-success btn-offer-logged"
                : "btn btn-light btn-offer-no-logged"
            }
            style={{ width: "100%" }}
            onClick={
              this.userOffered() || !(can_offer && started && !finished)
                ? this.makeOffer
                : () => this.setState({ show_confirm_modal: true })
            }
            id={"offer"}
          >
            <h5 className={"text text-white mb-0"}>
              <b>
                Ofertar{" "}
                {get(good, "next_offer", false) ? (
                  as_currency(good.next_offer, good.currency.code)
                ) : (
                  <i className={"fa fa-spinner fa-spin"}> </i>
                )}
              </b>
            </h5>
          </div>
          {/*{popover}*/}
        </div>
      );
      } else if (!Session.loggedIn(this.props.source)){
        var offer_btn = (
        <div className={"container mb-2 mr-1"} key={"WebSocketAlert"}>
          <div
            className={
              "badge badge-danger fade show text-justify"
            }
            role={"alert"}
            id={"alert"}
          >
            <b>
              Para ver ofertas en tiempo real, <a href={`/login/?next=${window.location}`}>Inicia Sesión</a>.
            </b>
          </div>
        </div>)
      // Disable offers from privadas to cba
      // if (this.props.source === 'cba' && this.props.site !== this.props.source) {
      //     offer_btn = <a className={can_offer && started && !finished ? 'btn btn-success' : 'btn btn-light'}
      //                    style={{width: '100%'}}
      //                    href={glink}
      //                    id={'offer'}
      //     >
      //         <h5 className={'text text-white mb-0'}>
      //             <b>Ofertar {get(good, 'next_offer', false) ? as_currency(good.next_offer, good.currency.code) :
      //                 <i className={'fa fa-spinner fa-spin'}> </i>}</b>
      //         </h5>
      //     </a>;
      // }
    }
    if (
      this.state.good.stream &&
      Object.keys(this.state.good.stream).length !== 0 &&
      this.imThisAuct()
    ) {
      offer_btn = null;
    }
    return offer_btn;
  }

  renderGoodData() {
    var good = this.state.good;
    var username = this.state.user.username;
    var can_offer =
      good.offers &&
      get(good.offers, 0, "") !== username &&
      Session.loggedIn() &&
      username &&
      !good.suspension;
    var started =
      new Date(good.start_date) <
      new Date(getNow(this.context.date, this.context.localTime));
    var finished =
      new Date(good.end_date) <
      new Date(getNow(this.context.date, this.context.localTime));
    var source_url = get(
      get(this.props.sources, this.props.source, {}),
      "url",
      ""
    );
    var auction_data = "";
    if (this.props.source !== "privadas") {
      auction_data = [
        <br key={"br1"} />,
        <Col key={"auction_data"} className={"text-center"}>
          <h5>Datos de la subasta</h5>
          <br />
          <p>
            <b>Expediente: </b>
            {get(good.decree, "proceeding")}
          </p>
          <p>
            <b>Entidad:</b>{" "}
            {get(good.decree, "vendor") ? get(good.decree, "vendor").name : ""}
          </p>
          {/*<p>*/}
          {/*    <b>Contacto:</b> {get(good.decree, 'vendor') ? get(good.decree, 'vendor').email : ''}*/}
          {/*</p>*/}
        </Col>,
        <br key={"br2"} />,
      ];
    }
    var good_data = (
      <Container>
        <br />
        <Row>
          {this.renderLawMinuteTag()}
          {this.renderStreamConf()}
          <Col md={"8"} lg={"8"} xs={"12"}>
            {this.renderImgs()}
          </Col>
          <Col md={"4"} lg={"4"} xs={"12"} className={"text-center"}>
            {this.renderHeader(started, finished)}
            {this.renderStreamOffers()}
            {this.renderBestBidder()}
            {this.renderRoomBtns()}
            {this.renderShareBtn()}

            {this.renderOfferBtn()}
            {this.renderCosts()}
            {this.renderPercent()}
            {this.renderAcceptance()}
            {this.props.site !== this.props.source ? this.renderOrigin() : null}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <br />
            <ul className="nav nav-pills nav-fill">
              {/*<li className="nav-item m-2">*/}
              {/*    <a className={`nav-link ${this.state.activeTab === 'location' ? '' : 'active'}`}*/}
              {/*       href="#location"*/}
              {/*       onClick={() => this.setActiveTab('location')}>Ubicación</a>*/}
              {/*</li>*/}
              <li className="nav-item m-2">
                <a
                  className={`nav-link ${
                    this.state.activeTab === "description" ? "" : "active"
                  }`}
                  href="#description"
                  onClick={() => this.setActiveTab("description")}
                >
                  <b>Descripción</b>
                </a>
              </li>
              <li className="nav-item m-2">
                <a
                  className={`nav-link ${
                    this.state.activeTab === "auctioneer" ? "" : "active"
                  }`}
                  href="#auctioneer"
                  onClick={() => this.setActiveTab("auctioneer")}
                >
                  <b>Contacto</b>
                </a>
              </li>
              {good.status !== "Presencial" ? (
                <li className="nav-item m-2">
                  <a
                    className={`nav-link ${
                      this.state.activeTab === "offers" ? "" : "active"
                    }`}
                    href="#offers"
                    onClick={() => {
                      this.setActiveTab("offers");
                      this.state.good.offers
                        ? console.log("")
                        : this.getOffers();
                    }}
                  >
                    <b>Ofertas</b>
                  </a>
                </li>
              ) : null}
              <li className="nav-item m-2">
                <a
                  className={`nav-link ${
                    this.state.activeTab === "questions" ? "" : "active"
                  }`}
                  href="#questions"
                  onClick={() => {
                    this.setActiveTab("questions");
                    this.state.questions
                      ? console.log("")
                      : this.getQuestions();
                  }}
                >
                  <b>Preguntas</b>
                </a>
              </li>
              <li className="nav-item m-2">
                <a
                  className={`nav-link ${
                    this.state.activeTab === "video" ? "" : "active"
                  }`}
                  href="#video"
                  onClick={() => this.setActiveTab("video")}
                >
                  <b>Video</b>
                </a>
              </li>
            </ul>
            <br />
          </Col>
          <Col md={12} className={"bg-white"}>
            <div className="tab-content" id="myTabContent">
              <div
                className={`tab-pane fade ${
                  this.state.activeTab === "auctioneer" ? "show active" : ""
                }`}
                id="auctioneer"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                {this.renderAuctioneer()}
              </div>
              {/*<div className={`tab-pane fade ${this.state.activeTab === 'location' ? 'show active' : ''}`}*/}
              {/*     id="location" role="tabpanel" aria-labelledby="profile-tab">*/}
              {/*    {this.renderLocation()}*/}
              {/*</div>*/}
              {good.status !== "Presencial" ? (
                <div
                  className={`tab-pane fade ${
                    this.state.activeTab === "offers" ? "show active" : ""
                  }`}
                  id="offers"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  {this.renderOffers()}
                </div>
              ) : null}
              <div
                className={`tab-pane fade ${
                  this.state.activeTab === "questions" ? "show active" : ""
                }`}
                id="questions"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                {this.renderQuestions()}
              </div>
              <div
                className={`py-2 tab-pane fade ${
                  this.state.activeTab === "description" ? "show active" : ""
                }`}
                id="description"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <h4 className={"text-center"}>
                  <b>{good.name}</b>
                </h4>
                <div dangerouslySetInnerHTML={{ __html: good.description }} />
                {auction_data}
                <Col className={"text-center"}>
                  {/*<a className={'btn btn-info'}*/}
                  {/*   href={`${source_url}` + `${get(good.decree, 'url')}`}>Ver*/}
                  {/*    decreto y acta</a>*/}
                  <div
                    className={`btn btn-${
                      this.state.show_decree_act ? "danger" : "success"
                    }`}
                    onClick={(e) => {
                      this.setState({
                        show_decree_act: !this.state.show_decree_act,
                      });
                    }}
                  >
                    {this.state.show_decree_act ? "Ocultar" : "Mostrar"} decreto
                    y acta
                  </div>
                </Col>
                <Col className={"text-center"}>{this.renderDecree()}</Col>
              </div>
              <div
                className={`tab-pane fade ${
                  this.state.activeTab === "video" ? "show active" : ""
                }`}
                id="video"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                {this.renderVideo()}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
    return good_data;
  }

  closeWebsockets() {
    if (this.state.websocket.length > 0) {
      for (var index in this.state.websocket) {
        var ws = this.state.websocket[index];
        ws.close();
      }
    }
  }

  messageReceived(evt) {
    this.closeWebsockets();
    var received_msg = evt.data;
    //location.reload();
    //imprime en la consola del navegador el mensaje, viene con los valores nuevos despues de ofertar
    var msg = received_msg.split("-");
    var toast = msg[0];
    var uname = toast.split(" ")[0];
    var bien = msg[1];
    var iduser = msg[2];
    var precio = msg[3];
    // toastr.error(toast);
    // if (this.container && uname != this.state.user.username) {
    //   this.container.error(toast, "Oferta", { closeButton: true });
    // }
    this.getMyOffer();
    this.getGoodData(true, true);
    this.setState({ new_offers: true, websocket: [], ws_error: false });
    //add_oferta(valor_actual, user, fecha);
    if (
      this.state.good.stream &&
      Object.keys(this.state.good.stream).length !== 0 &&
      this.imThisAuct()
    ) {
      this.getOffers();
    }
  }

  openWSocket() {
    if ("WebSocket" in window) {
      if (
        Session.loggedIn() &&
        Session.getSession()[this.props.source].username
      ) {
        // Let us open a web socket
        var ws = new WebSocket(
          `${this.props.sources[this.props.source].ws_path}/bien/${
            this.props.gid
          }/`
        );

        ws.onopen = function () {
          // Web Socket is connected, send data using send()
          // toastr.options.closeButton = true;
          // toastr.options.positionClass = "toast-top-left"
          console.log("Websocket connected", this.readyState);
          // if (this.container){
          //     this.container.success('Websocket connected', 'Websocket', {closeButton:true});
          // }
        };
        ws.onmessage = (evt) => this.messageReceived(evt);

        ws.onerror = (event) => {
          console.log(`WebSocketError: ${event}`);
          this.setState({
            ws_error: true,
          });
        };

        ws.onclose = function () {
          // websocket is closed.
          //window.location=window.location;
        };

        var state = this.state;
        state.websocket.push(ws);
        this.setState(state);
      } else {
        // alert("Inicia sesión para ver las ofertas en tiempo real.");
      }
    } else {
      // The browser doesn't support WebSocket
      alert("Tu navegador no soporta actualizacion automática de ofertas");
    }
  }

  render() {
    var img = this.props.sources[this.props.site].defaultImg;
    var photos = get(this.state.good, "photos", []);
    if (photos.length > 0) {
      img = photos[0].url;
    }
    // if (this.state.error instanceof TypeError || this.state.ws_error) {
    //   window.location.reload();
    // }

    return (
      <div className={"bg-sbackground"} style={{ marginTop: "3vh" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta
            name="theme-color"
            content={this.props.sources[this.props.site].theme_color}
          />
          <meta
            property="og:site_name"
            content={this.props.sources[this.props.site].title}
          />
          <meta
            property="og:description"
            content="Portal de Subastas Electrónicas."
          />
          <meta property="og:title" content={this.state.good.name} />
          <meta property="og:image" itemProp="image" content={img} />
          <meta property="og:type" content="website" />
          <title>{this.props.sources[this.props.site].title}</title>
          <link
            rel="canonical"
            href={this.props.sources[this.props.site].url}
          />
          <link
            rel="shortcut icon"
            type="image/png"
            href={this.props.sources[this.props.site].favicon}
          />
          <link
            rel="stylesheet"
            href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
            integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
            crossOrigin=""
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/toastr.js/2.1.3/toastr.min.css"
          />
        </Helmet>
        <main ref="main">
          <AuctionsNavbar
            filtersSetter={false}
            session={this.state.session}
            {...this.props}
          />
          <ToastContainer
            ref={(ref) => (this.container = ref)}
            className="toast-top-right"
          />
          <section
            className="section section-components pb-0 mt-xl-4"
            id="section-components"
            style={{'minHeight':'75vh'}}
          >
            { this.props.sources[this.props.site].demo ? 
              <div className="example-overlay">
                DEMOSTRACIÓN
              </div>
              :
              null
            }
            {this.renderDisclaimer()}
            {this.renderGoodData()}
            <Container className={"bg-white my-3 py-2 text-center"}>
              <h4>Ubicación</h4>
              {this.renderLocation()}
              {this.renderSeeInGoogleMapsBtn()}
            </Container>
            {this.renderConfirmModal(this.state.good)}
            {this.renderDownloadImgs()}
            <br />
          </section>
          <section id="bienes-similares">
            {
              <SimilarProducts
                cat_id={this.state.good.cat_id}
                subcat_id={this.state.good.subcat_id}
                {...this.props}
              />
            }
          </section>
          <SeFooter {...this.props} />
        </main>
      </div>
    );
  }
}

export default GoodView;
