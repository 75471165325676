import React, { useEffect, useState, useContext, useRef } from "react";
import { useDetectAdBlock } from "adblock-detect-react";

import styles from "./styles.module.scss";
import whatsappIcon from "../../assets/img/icons/common/whatsapp-white.svg";

import { sources, site } from "components/sources";
import { isMobile, getServerTime, getNow } from "../services";
import { TimeContext } from "contexts/TimeContext";
import Session from "components/session";
import { CloseButton } from "react-bootstrap";

const theme = `${sources[site].theme_name}`;
const background = `bg-danger`;

const Whatsapp = ({ adBlockDetected }) => {
  return (
    <a
      href="https://wa.me/5493513731991"
      target="_blank"
      className={`${styles["whatsapp-button"]}  ${
        adBlockDetected ? styles["whatsapp-button--to-bottom"] : ""
      } `}
    >
      <img src={whatsappIcon} alt="Comunicarse por whatsapp" />
    </a>
  );
};

const Clock = () => {
  const [serverTime, setServerTime] = useState(0);
  const [initTime, setInitTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [intervalList, setIntervalList] = useState([]);
  const [tooltipActive, setTooltipActive] = useState(false);
  const [showClock, setShowClock] = useState(false);

  const contextTime = useContext(TimeContext);

  const [now, setNow] = useState("");
  // setServerTime(sTime);

  let sTime = useRef(0);
  let lTime = useRef(0);
  let preresta = useRef(0);

  const [timers, setTimers] = useState({
    server: 0,
    initTime: 0,
    contextTime: 0,
    localTime: 0,
  });

  const initClock = () => {
    if (
      preresta.current > 0 &&
      Math.abs(Date.now() - preresta.current) > 2000
    ) {
      //  window.location.reload();
      //     console.log("reiniciar", Math.abs(Date.now() - preresta));
    }

    setNow((now) => {
      return getNow(sTime.current, lTime.current, "clock");
    });

    /**
     *
     */
    // console.log("preresta", preresta.current);
    preresta.current = Date.now();
    setTimeout(initClock, 1000);
  };

  useEffect(() => {
    setShowClock(
      Session.loggedIn() &&
        !isMobile() &&
        now > 0 &&
        Math.abs(now - Date.now()) > 2000
    );
  }, [now]);

  useEffect(() => {
    initClock(timers.server, timers.localTime);
  }, []);

  useEffect(() => {
    sTime.current = contextTime.date;
    lTime.current = contextTime.localTime;
  }, [contextTime.date]);

  const handleTooltip = () => {
    setTooltipActive(!tooltipActive);
  };

  //console.log(now);
  // console.log(timers);
  // console.log("fuera de scope", sTime, lTime);
  // console.log("now", now);
  // console.log("date now", Date.now());
  //
  // console.log("clock");
  // console.log("now", now);
  // console.log("date now", Date.now());
  // console.log("resta", now - contextTime.localTime);
  // console.log("preresta", preresta);

  // console.log("resta\n\r", now - Date.now());
  // console.log("-------------------------------------------");

  return (
    <>
      {showClock && (
        <>
          <div
            className={`${background} text-center ${styles["tooltip-clock"]} ${
              tooltipActive ? styles["tooltip-clock--active"] : ""
            }`}
          >
            <CloseButton onClick={handleTooltip} />
            <h3 className={"text text-white"}>¿Como sincronizar la hora?</h3>
            <p className={"text text-white"}>
              La hora en su dispositivo podría estar incorrecta.
              <br />
              ¿Cómo sincronizar la hora?
            </p>
            <a
              className="btn btn-primary btn-sm"
              href="https://support.microsoft.com/es-es/windows/c%C3%B3mo-establecer-la-hora-y-la-zona-horaria-dfaa7122-479f-5b98-2a7b-fa0b6e01b261"
              target="_blank"
            >
              En Windows
            </a>
            <a
              className="btn btn-primary btn-sm"
              target="_blank"
              href="https://support.apple.com/es-es/HT203413"
            >
              En MacOS
            </a>
            <a
              className="btn btn-primary btn-sm"
              href="https://help.ubuntu.com/stable/ubuntu-help/clock-set.html.es"
              target="_blank"
            >
              En Ubuntu
            </a>
          </div>
          <div
            className={`${background} ${styles["sticky-clock"]} text-center`}
            onMouseOver={handleTooltip}
          >
            Verifique la hora.
            <br />
            {new Date(now).toLocaleTimeString("en-GB", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}
          </div>
        </>
      )}
    </>
  );
};
export const StickyFooter = () => {
  const adBlockDetected = useDetectAdBlock();
  return (
    <>
      <Whatsapp adBlockDetected={adBlockDetected} />
      <Clock />
    </>
  );
};
