import { useEffect, useReducer } from "react";
import axios from "axios";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        loading: true,
        apiError: null,
        apiResponse: { response: "KO" },
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        loading: false,
        apiError: null,
        apiResponse: action.payload,
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        loading: false,
        apiError: action.payload,
        apiResponse: { response: "KO" },
      };
    default:
      return state;
  }
};

const useAxios = (accessApi, data) => {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    apiError: null,
    apiResponse: { response: "KO" },
  });

  useEffect(() => {
    let unmountComponent = false;
    const fetchData = async () => {
      dispatch({ type: "FETCH_INIT" });
      try {
        const response = await axios({
          method: accessApi.method,
          url: accessApi.url,
          data: data,
        });
        if (!unmountComponent) {
          dispatch({ type: "FETCH_SUCCESS", payload: response });
        }
      } catch (error) {
        if (!unmountComponent) {
          dispatch({ type: "FETCH_FAILURE", payload: error.response });
        }
      }
    };
    if (accessApi.url !== "") {
      fetchData();
    }
    return () => {
      unmountComponent = true;
    };
  }, [accessApi.type, accessApi.url, data]);

  return state;
};

export { useAxios };
