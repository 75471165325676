import * as React from "react";

import ClearCache from "react-clear-cache";
import { Button } from "reactstrap";

const CacheManagement = () => {
  // console.log('CacheManagement');
  return (
    <div>
      <ClearCache duration={60000} auto={true}>
        {({ isLatestVersion, emptyCacheStorage }) => (
          <div>
            {!isLatestVersion && (
              <p>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    emptyCacheStorage();
                  }}
                >
                  Update version
                </Button>
              </p>
            )}
          </div>
        )}
      </ClearCache>
    </div>
  );
};

export default CacheManagement;
