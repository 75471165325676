import React, { useEffect } from "react";
import { useState } from "react";
import { useAxios } from "hooks/useAxios";
import { sources, site } from "components/sources";
import { Col, Row, Container } from "reactstrap";
import Good from "./Good";

const removeDuplicates = (arr) => {
  var unique = [];
  arr.forEach((element) => {
    if (!unique.includes(element)) {
      unique.push(element);
    }
  });
  return unique;
};

export const SimilarProducts = (props) => {
  const [results, setResults] = useState([]);

  const [accessApi, setAccessApi] = useState({
    method: "GET",
    url: "",
  });

  const [data, setData] = useState({});

  const { loading, apiError, apiResponse } = useAxios(accessApi, data);

  const [calls, setCalls] = useState(0);

  const goods = [];

  const limit = 9;

  useEffect(() => {
    if (
      typeof props.cat_id !== "undefined" &&
      typeof props.subcat_id !== "undefined"
    ) {
      setAccessApi({
        method: "GET",
        url: `${sources[site].url}/api/good_search/?status=active&cat_id=${props.cat_id}&subcat_id=${props.subcat_id}&limit=${limit}`,
      });
    }
  }, [props.cat_id, props.subcat_id]);

  /** get "bienes que podrian interesarle" */
  useEffect(() => {
    if (
      !loading &&
      !apiError &&
      apiResponse.response != "KO" &&
      accessApi.url == apiResponse.request.responseURL
    ) {
      setCalls(calls + 1);
      setResults([
        ...results,
        ...apiResponse.data.results.filter((item) => {
          return item.id != props.gid;
        }),
      ]);
    }
  }, [loading, apiError, accessApi.url]);

  useEffect(() => {
    if (
      results.length < limit - 1 &&
      typeof props.cat_id !== "undefined" &&
      typeof props.subcat_id !== "undefined"
    ) {
      let call_url =
        calls == 1 //called once with subcat_id
          ? `${sources[site].url}/api/good_search/?status=active&cat_id=${props.cat_id}&limit=${limit}`
          : `${sources[site].url}/api/good_search/?status=active&limit=${limit}`;

      setAccessApi({
        ...accessApi,
        url: call_url,
      });
    }
  }, [results]);

  if (results.length > 0) {
    const limit = 8;
    let i = 0;
    const items = [];

    results.forEach((good) => {
      let found = items.find((element) => {
        return element == good.id;
      });
      if (!found) {
        items.push(good.id);
      }

      if (i < limit && !found) {
        goods.push(
          <Good
            key={`${good.source}_${good.id}`}
            sources={sources}
            addToRoom={true}
            removeFromRoom={true}
            good={good}
            entity_iso={sources[site].favicon}
            {...props}
          />
        );
        i++;
      }
    });
  }

  return goods.length > 0 ? (
    <Container className={" my-3 py-2 text-center"}>
      <Row className={" mb-1"}>
        <Col lg={"12 bg-white pb-1 pt-1   "}>
          <h4 className="col-center">Bienes que podrían interesarle </h4>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={"12"}>
          <Row className={"text-center"}>{goods}</Row>
        </Col>
      </Row>
    </Container>
  ) : (
    <></>
  );
};
