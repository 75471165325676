import React from "react";
import AuctionsNavbar from "../components/Navbars/AuctionsNavbar";
import SeFooter from "components/Footers/SeFooter";
import { Helmet } from "react-helmet";
import CacheManagement from "../components/CacheManagement";
import { Container } from "reactstrap";
import TagManager from "react-gtm-module";
import { site, sources } from "./sources.js";

const Base = (props) => {
  const [gadScript, setGadScript] = React.useState(false);

  React.useEffect(() => {
    if (!gadScript) {
      let tagManagerArgs = {
            gtmId: sources[site].gtm_ID,
      };
      if (sources[site].tagManagerArgs){
        tagManagerArgs = sources[site].tagManagerArgs;
      }
      TagManager.initialize(tagManagerArgs);
      const script = document.createElement("script");
      script.innerHTML =
        "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-368678964');";
      document.head.appendChild(script);
      setGadScript(true);
    }
  });

  return (
    <div className={"bg-sbackground"}>
      <Helmet>
        <meta charSet={"utf-8"} />
        <meta
          name={"viewport"}
          content={"width=device-width, initial-scale=1, shrink-to-fit=no"}
        />
        <meta
          name={"theme-color"}
          content={props.sources[props.site].theme_color}
        />
        <meta property={"og:site_name"} content={props.site_name} />
        <meta property={"og:description"} content={props.site_desc} />
        <meta property={"og:title"} content={props.sources[props.site].title} />
        <meta
          property={"og:image"}
          itemProp={"image"}
          content={props.link_img}
        />
        <meta property={"og:type"} content={"website"} />
        {props.meta ? props.meta : null}
        <title>{props.sources[props.site].title}</title>
        <link rel={"canonical"} href={props.sources[props.site].url} />
        <link
          rel={"shortcut icon"}
          type={"image/png"}
          href={props.sources[props.site].favicon}
        />
        {props.site === "privadas" ? (
          <script
            data-ad-client={"ca-pub-7683162712895948"}
            async
            src={
              "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
            }
          ></script>
        ) : null}
      </Helmet>
      <main>
        <AuctionsNavbar {...props} />
        <section
          className={"section section-components pb-0 mt-lg-4"}
          id={"section-components"}
          style={{'minHeight':'75vh'}}
        >
          <Container className={"mt-5 position-relative"}>
            { props.sources[props.site].demo ? 
              <div className="example-overlay">
                <div>
                  DEMOSTRACIÓN
                </div>
              </div>
              :
              null
            }
              
            <CacheManagement />
            {props.children}
          </Container>
        </section>

        <SeFooter {...props} />
      </main>
    </div>
  );
};

export default Base;
