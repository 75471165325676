import React from "react";
import { atcb_init } from "add-to-calendar-button";
import 'add-to-calendar-button/assets/css/atcb.css';
import { options } from "headroom.js";
import styles from "./style.module.scss"


const SaveInCalendar = (props) => {

    const [data, setData] = React.useState({});

    if (props.good.name && Object.keys(data).length === 0){
        let url = window.location.href;
        let s_date,s_time, e_date, e_time;
        let startDateNr = new Date(props.good.end_date);
        let startDateMs = startDateNr.getTime();
        let hourMs = 60 * 60000;
        let startDateR = new Date(startDateMs - hourMs);
        let endDate = new Date(props.good.end_date);
        [s_date] = startDateR.toISOString().split('T');
        [e_date] = endDate.toISOString().split('T');
        let s_time_hours = startDateR.getHours().toString().padStart(2, "0");
        let s_time_minutes = startDateR.getMinutes().toString().padStart(2, "0");
        let e_time_hours = endDate.getHours().toString().padStart(2, "0");
        let e_time_minutes = endDate.getMinutes().toString().padStart(2, "0");
        s_time = s_time_hours+":"+s_time_minutes
        e_time = e_time_hours+":"+e_time_minutes

        setData({
            name: props.good.name,
            startDate: s_date,
            endDate: e_date,
            startTime: s_time,
            endTime: e_time,
            location: url,
            lightMode: "bodyScheme",
            label: "Agregar a Calendario",
            options:[
                    "Apple",
                    "Google",
                    "iCal",
                    "Microsoft365",
                    "MicrosoftTeams",
                    "Outlook.com",
                    "Yahoo",
                    ],
            timeZone: "America/Argentina/Cordoba",
            iCalFileName: "Subasta_Electronica",
            inline: true,
            trigger: "click"
        });

    }

    if(Object.keys(data).length > 0) {
        atcb_init();

    }
    return (
        <div className={`atcb btn-add-to-calendar`}>
            {JSON.stringify(data)}
        </div>
    )

}



export default SaveInCalendar;
