import React from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {reverse, get, as_currency} from "../components/services";

export function renderConfirmModal(good) {
    var res = <Modal id={'ConfirmModal'} isOpen={this.state.show_confirm_modal}>
                <ModalHeader>Primera Oferta</ModalHeader>
                <ModalBody>
                    <p>Ratifico mi interés en ofertar por el producto: <b>{good.name}</b>,
                        respetando el <a href={reverse('terms')} target={'_blank'}><b>Reglamento</b></a> y asumiendo las consecuencias ante cualquier incumplimiento.</p>
                    <p className={'text text-danger'}>
                        <b>Una vez realizada la oferta, no puede eliminarse.</b>
                    </p>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <div className={'btn btn-success'}
                            style={{width: '100%'}}
                            onClick={() => {
                                this.makeOffer();
                                this.setState({show_confirm_modal: false})
                            }}
                            id={'offer'}
                        >
                            <h5 className={'text text-white mb-0'}>
                                <b>Ofertar {get(good, 'next_offer', false) ? as_currency(good.next_offer) :
                                    <i className={'fa fa-spinner fa-spin'}> </i>}</b>
                            </h5>
                        </div>
                    </div>
                    <div className={'btn btn-danger'} onClick={() => this.setState({show_confirm_modal: false})}>Cancelar
                    </div>
                </ModalFooter>
            </Modal>;
    return res;
}