import {deleteAllCookies, getCookie, setCookie} from "./services";
import {sources} from "./sources.js";

var Session = (function () {

    // const sources = {
    //     privadas: {
    //         url: 'https://subastaselectronicas.com.ar',
    //         logo: SELogo,
    //         ws_path: 'subastaselectronicas.com.ar/wss',
    //     },
    //     cba: {
    //         url: 'https://subastas.justiciacordoba.gob.ar',
    //         logo: CbaLogo,
    //         ws_path: 'subastas.justiciacordoba.gob.ar/wss'
    //     },
    //     sl: {
    //         url: 'https://subastas.justiciasanluis.gov.ar',
    //         logo: SLLogo,
    //         ws_path: 'subastas.justiciasanluis.gov.ar/wss',
    //     },
    //     local: {
    //         url: window.location.href.split(':3000')[0],
    //         logo: SELogo,
    //         ws_path: 'localhost/wss'
    //     },
    // }

    var getSession = function () {
        var session = {};
        var storage = window.localStorage;
        let token, username, email, role, doc, public_doc;
        for (var source in sources) {
            token = getCookie(`${source}_token`);
            username = getCookie(`${source}_username`);
            email = getCookie(`${source}_email`);
            role = getCookie(`${source}_role`);
            doc = getCookie(`${source}_doc`);
            public_doc = getCookie(`${source}_public_doc`);
            session[source] = {
                token: token !== 'undefined' ? token : null,
                username: username !== 'undefined' ? username : null,
                email: email !== 'undefined' ? email : null,
                role: role !== 'undefined' ? role : null,
                doc: doc !== 'undefined' ? doc : null,
                public_doc: public_doc !== 'undefined' ? public_doc : null,
            };
            if (session[source].token) {
                storage.setItem(`${source}_token`, session[source].token);
                storage.setItem(`${source}_username`, session[source].username);
                storage.setItem(`${source}_email`, session[source].email);
                storage.setItem(`${source}_role`, session[source].role);
                storage.setItem(`${source}_doc`, session[source].doc);
                storage.setItem(`${source}_public_doc`, session[source].public_doc);
            } else {
                token = storage.getItem(`${source}_token`);
                username = storage.getItem(`${source}_username`);
                email = storage.getItem(`${source}_email`);
                role = storage.getItem(`${source}_role`);
                doc = storage.getItem(`${source}_doc`);
                public_doc = storage.getItem(`${source}_public_doc`);
                session[source] = {
                    token: token !== 'undefined' ? token : null,
                    username: username !== 'undefined' ? username : null,
                    email: email !== 'undefined' ? email : null,
                    role: role !== 'undefined' ? role : null,
                    doc: doc !== 'undefined' ? doc : null,
                    public_doc: public_doc !== 'undefined' ? public_doc : null,
                };
            }
        }
        return session;
    }

    var setSession = function (source, values) {
        var storage = window.localStorage;
        for (var key in values) {
            setCookie(`${source}_${key}`, values[key], 14);
            storage.setItem(`${source}_${key}`, values[key]);
        }
        setCookie(`token`, values.token, 14);
        storage.setItem(`token`, values.token);
    }

    var loggedIn = function (source = null) {
        var session = this.getSession();
        var res = false;
        if (source) {
            res = this.getUsername(source) !== null;
        } else {
            for (var sname in sources) {
                res = res || (session[sname].token !== null && session[sname].token !== '');
            }
        }
        return res;
    }

    var logOut = function (source = null) {
        deleteAllCookies();
        var keys = [
            'token',
            'username',
            'email',
            'role',
            'doc',
            'public_doc'
        ];
        var storage = window.localStorage;
        if (source) {
            for (var i in keys) {
                var key = keys[i];
                setCookie(`${source}_${key}`, '', -1);
                storage.setItem(`${source}_${key}`, '');
            }
        } else {
            for (var sname in sources) {
                // setCookie(`token`, '', 1);
                for (var j in keys) {
                    var k = keys[j];
                    setCookie(`${sname}_${k}`, '', -1);
                    storage.setItem(`${sname}_${k}`, '');
                }
            }
        }
    }

    var getSource = function () {
        var session = this.getSession();
        for (var source in sources) {
            if (session[source].username !== '') {
                return source;
            }
        }
        return null;
    }

    var getUsername = function (source = false) {
        if (!source) {
            source = this.getSource();
        }
        var session = this.getSession();
        var res = null;
        if (source && session[source] && session[source].username) {
            res = session[source].username;
        }
        return res;
    }

    var getRole = function (source = false) {
        if (!source) {
            source = this.getSource();
        }
        var session = this.getSession();
        var res = null;
        if (source && session[source] && session[source].role) {
            res = session[source].role;
        }
        return res;
    }

    var getToken = function (source = false) {
        if (!source) {
            source = this.getSource();
        }
        var session = this.getSession();
        var res = null;
        if (source && session[source] && session[source].token) {
            res = session[source].token;
        }
        return res;
    }

    var synced = function () {
        let username;
        var synced = true;
        var i = 0;
        for (var source in sources) {
            if (i === 0) {
                username = this.getUsername(source);
            } else {
                synced = synced && username === this.getUsername(source);
            }
            i++;
        }
        return synced;
    }


    return {
        getSession: getSession,
        setSession: setSession,
        loggedIn: loggedIn,
        logOut: logOut,
        getSource: getSource,
        getUsername: getUsername,
        getRole: getRole,
        getToken: getToken,
        synced: synced,
    }
})();

export default Session;