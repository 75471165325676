import React, {useState} from 'react';
import {Col, 
        Row, 
        Button,
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,
        Input,
} from 'reactstrap';
import Session from "components/session";
import {reverse, apiRequestHeaders} from "components/services";

let UnameReport = props => {
    const [modal, setModal] = useState(false);
    const [username, setUname] = useState(props.username);
    const [motivo, setMotivo] = useState(null);
    const [sent, setSent] = useState(false);

    const toggle = () => setModal(!modal);

    let ta_pholder = 'Motivo por el que cree debe cambiarse el ' +
                     'nombre de este usuario. (opcional)';

    const send = () => {
        if (username){
            var data = {
                username: username,
                motivo: motivo
            }
            var site = props.source ? props.source : props.site;
            var url = reverse('unamereport', site, [], {}, props.sources);
            var requestOptions = {
                headers: apiRequestHeaders(Session.getToken(props.site)),
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(data),
            };
            fetch(url, requestOptions)
            .then(
                (result) => {
                    setSent(true);
                },
                (error) => {
                    setSent(true);
                    console.log(error);
                }
            )
        }
        return;
    }

    var body = <ModalBody>
                    <p>
                        <b>Algunos nombres de usuarios pueden resultar ofensivos o confundir a otros.</b>
                    </p>
                    <p>
                        <b>Aquí puedes solicitar que cambiemos el nombre de algún usuario que consideres
                        no debería usarse.</b>
                    </p>
                    <Input type={'text'}
                        placeholder={'Nombre de usuario ofensivo o confuso'}
                        id={'off_uname'}
                        className={'form-control'}
                        value={username}
                        onChange={e => setUname(e.target.value)}
                        maxLength={150}
                        valid={username}
                        invalid={!username}
                    />
                    <br />
                    <textarea placeholder={ta_pholder}
                              id={'motivo'}
                              onChange={e => setMotivo(e.target.value)}
                              value={motivo}
                              className={'form-control'}
                              maxLength={200}
                    >
                    </textarea>
                </ModalBody>;

    if (sent){
        body = <ModalBody className={'text text-center'}>
                    <h4>Gracias por ayudarnos a mejorar!</h4>
                    <br />
                    <p>
                        <b>
                            Nuestro equipo evaluará tu pedido y, si el nombre del usuario
                            es inapropiado, le pediremos que lo cambie.
                        </b>
                    </p>
                </ModalBody>;
    }

    var res = null;
    if (Session.loggedIn(props.source)){
        res = <div className={`text text-${props.alignment ? props.alignment : 'center'}`}>
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Denunciar nombre de usuario</ModalHeader>
                    {body}
                    <ModalFooter>
                    { sent ? 
                        <Button color="primary" onClick={() => {toggle(); setUname(''); setMotivo(''); setSent(false)}}>Listo!</Button>
                    :
                        <>
                        <Button color={`primary`} onClick={send} disabled={!username}>Enviar</Button>{' '}
                        <Button color="secondary" onClick={toggle}>Cancelar</Button>
                        </>
                    }
                    </ModalFooter>
                </Modal>
                <a className={`btn btn-sm btn-${props.theme ? props.theme : props.sources[props.site].theme_name}`}
                   onClick={toggle}
                   href={props.section ? props.section : '#'}
                >
                    Denunciar nombre de usuario
                </a>
            </div>;
    }
    return res
}

export default UnameReport;