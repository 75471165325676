/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, withRouter } from "react-router-dom";

import Headroom from "headroom.js";
// JavaScript plugin that hides or shows a component based on your scroll
// reactstrap components
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import classnames from "classnames";
import { apiRequestHeaders, get, reverse } from "../services";
import Session from "../session";

class AuctionsNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ended: false,
      collapseClasses: "",
      collapseOpen: false,
      search_term: this.props.filters
        ? get(this.props.filters, "search_term", "").replace(/%20/g, " ")
        : "",
      notifications: [],
      notifications_count: 0,
    };
    this.logOut = this.logOut.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
  }

  logOut() {
    if (this.props.site === "privadas") {
      Session.logOut();
    } else {
      Session.logOut(this.props.site);
    }
    // this.setState({});
    window.location = "/logout/";
  }

  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    var headroom_class = this.props.sources[this.props.site].headroom_class;
    var classes = headroom.classes;
    for (var key in classes) {
      classes[key] = classes[key].replace("headroom", headroom_class);
    }
    // initialise
    headroom.init();
    this.getNotifications();

    //remove init sedingblue chat to stickyFooter
  }

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  setSearchTerm(event) {
    this.setState({
      search_term: event.target.value,
    });
  }

  setSearch() {
    var value = this.state.search_term;
    if (this.props.filtersSetter) {
      this.props.filtersSetter({ search_term: value });
    } else {
      this.props.history.push(`/search/${value}/`);
    }
  }

  toggleFilters(event) {
    var id = event.target.id;
    var kwargs = {
      status: "active",
      cat_id: "",
    };
    if (id !== "" && this.props.filters.status !== id) {
      kwargs = {
        status: id,
        cat_id: "",
      };
    }
    this.props.filtersSetter(kwargs);
  }

  renderFilters() {
    var filters = [];
    for (var filter in this.props.av_filters) {
      var name = this.props.av_filters[filter];
      if (this.props.filters.status === filter) {
        name = <b>{this.props.av_filters[filter]}</b>;
      }
      filters.push(
        <DropdownItem
          key={filter}
          id={filter}
          onClick={(e) => this.toggleFilters(e)}
        >
          {name}
        </DropdownItem>
      );
    }
    return (
      <UncontrolledDropdown nav>
        <DropdownToggle nav>
          <i className="ni ni-collection d-lg-none mr-1" />
          <span className="nav-link-inner--text">Filtros</span>
        </DropdownToggle>
        <DropdownMenu>{filters}</DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  toggleCats(event) {
    var id = event.target.id;
    var kwargs = { cat_id: id };
    if (id === this.props.filters.cat_id) {
      kwargs = { cat_id: "" };
    }
    this.props.filtersSetter(kwargs);
  }

  renderCats() {
    var cats = [];
    for (var id in this.props.categories) {
      var cat = this.props.categories[id];
      var style = "btn-light";
      if (this.props.filters.cat_id === id) {
        style = "btn-success";
      }
      cats.push(
        <div
          key={cat.name}
          className={`col-xs-5 btn btn-sm ${style} my-2`}
          id={`${id}`}
          onClick={(e) => this.toggleCats(e)}
        >
          {cat.name}
        </div>
      );
    }
    return (
      <UncontrolledDropdown nav>
        <DropdownToggle nav>
          <i className="ni ni-ui-04 d-lg-none mr-1" />
          <span className="nav-link-inner--text">Categorias</span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-xl">
          <div className="dropdown-menu-inner text-center">{cats}</div>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  renderSearch() {
    return (
      <NavItem className="d-lg-block ml-lg-4" style={{ marginTop: "1.5vh" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.setSearch.bind(this);
          }}
          action={"#"}
        >
          <FormGroup
            className={classnames({
              focused: this.state.searchAltFocused,
            })}
          >
            <InputGroup className="input-group-alternative">
              <Input
                type="text"
                id={"search_term"}
                value={this.state.search_term}
                className={"px-2"}
                onChange={(e) => this.setSearchTerm(e)}
              />
              <button
                className={"btn btn-md btn-default"}
                onClick={this.setSearch.bind(this)}
              >
                <i className={"fa fa-search"}> </i>&nbsp;
                {/* Buscar */}
              </button>
            </InputGroup>
          </FormGroup>
        </form>
      </NavItem>
    );
  }

  rendeLogin() {
    var button = (
      <>
        <Col className={"text-center my-2"} xs={12} md={12}>
          <a
            className={`btn btn-sm btn-default text-white`}
            href={"/login/"}
          >
            Iniciar Sesión
          </a>
        </Col>
        <Col className={"text-center my-2"} xs={12} md={12}>
          {/* Desktop version of the login button   */}
          <ul className="navbar-nav navbar-nav-hover align-items-lg-center d-none d-sm-block ml-3">
            <li className="nav-item dropdown">
              <div
                className={`btn btn-sm btn-default text-white`}
                data-toggle="dropdown"
                role="button"
              >
                <span className="nav-link-inner--text">Registrarme <i className={'fa fa-caret-down'}/></span>
              </div>
              <div className="dropdown-menu">
                <a href={reverse("registro_fisica")} className="dropdown-item">
                  Persona
                </a>
                <a href={reverse("registro_juridica")} className="dropdown-item">
                  Empresa
                </a>
              </div>
            </li>
          </ul>
          {/* Mobile version of the login button */}
          <UncontrolledDropdown nav inNavbar className={`d-block d-sm-none`}>
            <DropdownToggle nav>
              <div className={`btn btn-sm btn-default dropdown-toggle text-white`}>
                Registrarme
              </div>
            </DropdownToggle>
            <DropdownMenu aria-labelledby="nav-inner-primary_dropdown_1" right>
              <DropdownItem href={reverse("registro_fisica")}>
                Persona
              </DropdownItem>
              <DropdownItem href={reverse("registro_juridica")}>
                Empresa
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </>
    );
    if (Session.loggedIn(this.props.site)) {
      var user_panel_url = reverse(
        "user_panel",
        this.props.site,
        [],
        {},
        this.props.sources
      );
      var role = Session.getRole(this.props.site);
      if (role === "MR") {
        user_panel_url = reverse(
          "auctioneer_panel",
          this.props.site,
          [],
          {},
          this.props.sources
        );
      } else if (role === "TR") {
        user_panel_url = reverse(
          "court_panel",
          this.props.site,
          [],
          {},
          this.props.sources
        );
      } else if (role === "AM") {
        user_panel_url = reverse(
          "admin_panel",
          this.props.site,
          [],
          {},
          this.props.sources
        );
      } else if (role === "TE") {
        user_panel_url = reverse(
          "treasury_panel",
          this.props.site,
          [],
          {},
          this.props.sources
        );
      }
      button = (
        <>
        {/* Desktop version of the user button */}
        <ul className="navbar-nav navbar-nav-hover align-items-lg-center d-none d-sm-block ml-3">
          <li className="nav-item dropdown">
            <div
              className={`btn btn-sm btn-default text-white`}
              data-toggle="dropdown"
              role="button"
            >
              <span className="nav-link-inner--text"><b>{Session.getUsername(this.props.site)}</b> <i className={'fa fa-caret-down'}/></span>
            </div>
            <div className="dropdown-menu">
              <a href={user_panel_url} className="dropdown-item">
                Panel de usuario
              </a>
              <a href={"/auction_room/"} className="dropdown-item">
                Sala de Subastas
              </a>
              <a onClick={this.logOut} className="dropdown-item">
                Cerrar Sesión
              </a>
            </div>
          </li>
        </ul>
        {/* Mobile version of the user button */}
        <UncontrolledDropdown nav inNavbar className={`d-block d-sm-none`}>
          <DropdownToggle nav>
            <div
              className={`btn btn-sm btn-default dropdown-toggle text-white`}
            >
              <b>{Session.getUsername(this.props.site)}</b>{" "}
            </div>
          </DropdownToggle>
          <DropdownMenu aria-labelledby="nav-inner-primary_dropdown_1" right>
            <DropdownItem href={user_panel_url}>Panel de usuario</DropdownItem>
            <DropdownItem href={"/auction_room/"}>
              Sala de Subastas
            </DropdownItem>
            <DropdownItem onClick={this.logOut}>Cerrar Sesión</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        </>
      );
    }
    return <div className="d-lg-block ml-lg-4">{button}</div>;
  }

  renderFacebook() {
    return (
      <NavItem>
        <NavLink
          className="nav-link-icon"
          href="https://www.facebook.com/subastaelectronica/"
          id="tooltip333589074"
          target="_blank"
        >
          <i className="fa fa-facebook-square" />
          <span className="nav-link-inner--text d-lg-none ml-2">Facebook</span>
        </NavLink>
        <UncontrolledTooltip delay={0} target="tooltip333589074">
          Danos un me gusta!
        </UncontrolledTooltip>
      </NavItem>
    );
  }

  renderInstagram() {
    return (
      <NavItem>
        <NavLink
          className="nav-link-icon"
          href="https://www.instagram.com/esubasta/"
          id="tooltip356693867"
          target="_blank"
        >
          <i className="fa fa-instagram" />
          <span className="nav-link-inner--text d-lg-none ml-2">Instagram</span>
        </NavLink>
        <UncontrolledTooltip delay={0} target="tooltip356693867">
          Seguinos!
        </UncontrolledTooltip>
      </NavItem>
    );
  }

  renderTwitter() {
    return (
      <NavItem>
        <NavLink
          className="nav-link-icon"
          href="https://twitter.com/esubasta"
          id="tooltip184698705"
          target="_blank"
        >
          <i className="fa fa-twitter-square" />
          <span className="nav-link-inner--text d-lg-none ml-2">Twitter</span>
        </NavLink>
        <UncontrolledTooltip delay={0} target="tooltip184698705">
          Seguinos en Twitter!
        </UncontrolledTooltip>
      </NavItem>
    );
  }

  renderLinkedin() {
    return (
      <NavItem>
        <NavLink
          className="nav-link-icon"
          href="https://www.linkedin.com/company/subastas-electr%C3%B3nicas/"
          id="tooltip951357842"
          target="_blank"
        >
          <i className="fa fa-linkedin-square" />
          <span className="nav-link-inner--text d-lg-none ml-2">Linkedin</span>
        </NavLink>
        <UncontrolledTooltip delay={0} target="tooltip951357842">
          Seguí nuestro perfil!
        </UncontrolledTooltip>
      </NavItem>
    );
  }

  renderNotifications() {
    let res = null;
    if (this.state.notifications.length > 0) {
      let nots_mobile = [];
      let nots = [];
      for (var index in this.state.notifications) {
        let not = this.state.notifications[index];
        nots_mobile.push(
          <DropdownItem href={not.link} key={`m_${not.id}`}>
            {not.mensaje}
          </DropdownItem>
        );
        nots.push(
          <a href={not.link} key={not.id} className="dropdown-item">
            {not.mensaje}
          </a>
        );
      }
      res = (
        <>
        {/* Desktop version of the notifications button */}
        <ul className="navbar-nav navbar-nav-hover align-items-lg-center d-none d-sm-block ml-3">
          <li className="nav-item dropdown">
            <div
              className={`btn btn-sm btn-danger text-white`}
              data-toggle="dropdown"
              role="button"
            >
              <span className="nav-link-inner--text dropdown-toggle">
                <b>{this.state.notifications_count}</b>
                <i className="fa fa-bell" />
                <span className="nav-link-inner--text d-lg-none ml-2">
                  Notificaciones
                </span>
              </span>
            </div>
            <div className="dropdown-menu">
              {nots}
            </div>
          </li>
        </ul>
        {/* Mobile version of the notifications button */}
        <UncontrolledDropdown nav inNavbar className={`d-block d-sm-none`}>
          <DropdownToggle nav>
            <div className={`btn btn-sm btn-danger dropdown-toggle text-white`}>
              <b>{this.state.notifications_count}</b>
              <i className="fa fa-bell" />
              <span className="nav-link-inner--text d-lg-none ml-2">
                Notificaciones
              </span>
            </div>
          </DropdownToggle>
          <DropdownMenu aria-labelledby="nav-inner-primary_dropdown_1" right>
            {nots_mobile}
          </DropdownMenu>
        </UncontrolledDropdown>
        </>
      );
          }
    return res;
  }

  renderRightSide() {
    return (
      <Nav className="align-items-lg-center ml-lg-auto" navbar>
        {/* {this.props.site === "privadas" ? this.renderFacebook() : null}
        {this.props.site === "privadas" ? this.renderInstagram() : null}
        {this.props.site === "privadas" ? this.renderTwitter() : null}
        {this.props.site === "privadas" ? this.renderLinkedin() : null} */}
        {this.rendeLogin()}
        {this.renderNotifications()}
        {this.renderSearch()}
      </Nav>
    );
  }

  getNotifications() {
    if (Session.loggedIn(this.props.site)) {
      var kwargs = { limit: 30 };
      var url = reverse(
        "notification",
        this.props.site,
        [],
        kwargs,
        this.props.sources
      );
      const requestOptions = {
        headers: apiRequestHeaders(Session.getToken(this.props.site)),
        method: "GET",
        mode: "cors",
      };
      fetch(url, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            window.location = reverse("logout");
          }
          return res.json();
        })
        .then(
          (result) => {
            var results = [];
            var count = 0;
            if (result.results) {
              results = result.results;
              count = result.count;
            }
            this.setState({
              notifications: results,
              notifications_count: count,
            });
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  render() {
    var navbar_class = this.props.sources[this.props.site].navbar_class;
    var headroom_class = this.props.sources[this.props.site].headroom_class;
    var style = `navbar-main ${navbar_class} navbar-light ${headroom_class} bb-5`;
    var logo = this.props.sources[this.props.site].logo;
    var logolight = this.props.sources[this.props.site].logolight;
    return (
      <>
        <header className="header-global">
          <Navbar className={style} expand="lg" id="navbar-main">
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" href={"/"}>
                {/* En dispositivos móviles */}
                <img
                  className={"img img-responsive d-block d-sm-none"}
                  alt="Subastas Electrónicas"
                  src={logolight}
                  style={{
                    marginTop: "0px",
                    height: `${"8vh"}`,
                    maxHeight: "100px",
                  }}
                />
                {/* En PC's */}
                <img
                  className={"img img-responsive d-none d-sm-block mx-1"}
                  alt="Subastas Electrónicas"
                  src={logolight}
                  style={{
                    marginTop: "0px",
                    height: `${"9vh"}`,
                    maxHeight: "100px",
                  }}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img alt="..." src={logo} />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                {/*<Nav className="navbar-nav-hover align-items-lg-center" navbar>*/}
                {/*{this.props.render_filters ? this.renderCats() : null}*/}
                {/*{this.props.render_filters ? this.renderFilters() : null}*/}
                {/*</Nav>*/}
                {this.renderRightSide()}
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default withRouter(AuctionsNavbar);
