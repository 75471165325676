/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

// import Index from "views/Index.js";
import GoodView from "views/GoodView/GoodView.js";
// import Login from "views/Login.js";
// import AuctionRoomView from "views/AuctionRoom/AuctionRoomView.js"
// import Login from "views/examples/Login";
// import Profile from "views/examples/Profile.js";
// import Register from "views/examples/Register.js";
// import Landing from "./views/examples/Landing";
import { site, sources } from "./components/sources.js";

// import PaymentMedia from "./views/PaymentMedia.js";
// import PaymentTime from "./views/PaymentTime.js";
// import HowPay from "./views/HowPay.js";
// import FAQ from "./views/FAQ.js";
// import Guides from "./views/Guides.js";
// import Contact from "./views/Contact.js";
// import RemainingPercentage from "./views/RemainingPercentage";
// import WannaAuction from "./views/WannaAuction";
// import BroadcastHelp from "./views/BroadcastHelp";
// import RecoverPassword from "./views/RecoverPassword";
// import RegisterPerson from "./views/RegisterPerson";
// import RegisterEnterprise from "./views/RegisterEnterprise";
// import Backup from "./views/APIDocs/Backup";
// import AuthToken from "./views/APIDocs/AuthToken";
// import TagManager from 'react-gtm-module';
// import PrivacyPolicy from "./views/PrivacyPolicy";
// import ValidationIE from "views/Validation/ValidationIE";
// import ValidationSE from "views/Validation/ValidationSE";
import TermsAndConditions from "views/TermsAndConditions/TermsAndConditions";
import loadable from "@loadable/component";
import { TimeContext } from "contexts/TimeContext.js";
import { setGlobalTime } from "components/services.js";
import Session from "./components/session";

import ReactPixel from "react-facebook-pixel";
if (site == "privadas") {
  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init("503129231590173", advancedMatching, options);
  ReactPixel.pageView();
  /*** Facebook pixel */
}

const Index = loadable(() => import("views/Index.js"));
// const GoodView = loadable(() => import("views/GoodView/GoodView.js"));
const AuctionRoomView = loadable(() =>
  import("views/AuctionRoom/AuctionRoomView.js")
);
const Login = loadable(() => import("views/Login.js"));
const Profile = loadable(() => import("views/examples/Profile.js"));
const Register = loadable(() => import("views/examples/Register.js"));
const Landing = loadable(() => import("./views/examples/Landing"));
// const site = loadable(() => import("./components/sources.js"), {
//        resolveComponent: (components) => console.log(components),
// });
// const sources = loadable(() => import("./components/sources.js"), {
//        resolveComponent: (components) => components.sources,
// });
const PaymentMedia = loadable(() => import("./views/PaymentMedia.js"));
const PaymentTime = loadable(() => import("./views/PaymentTime.js"));
const HowPay = loadable(() => import("./views/HowPay.js"));
const FAQ = loadable(() => import("./views/FAQ.js"));
const Guides = loadable(() => import("./views/Guides.js"));
const Contact = loadable(() => import("./views/Contact.js"));
const RemainingPercentage = loadable(() =>
  import("./views/RemainingPercentage.js")
);
const WannaAuction = loadable(() => import("./views/WannaAuction.js"));
const BroadcastHelp = loadable(() => import("./views/BroadcastHelp.js"));
const RecoverPassword = loadable(() => import("./views/RecoverPassword.js"));
const RegisterPerson = loadable(() => import("./views/RegisterPerson.js"));
const RegisterEnterprise = loadable(() =>
  import("./views/RegisterEnterprise.js")
);
const Backup = loadable(() => import("./views/APIDocs/Backup.js"));
const AuthToken = loadable(() => import("./views/APIDocs/AuthToken.js"));
const PrivacyPolicy = loadable(() => import("./views/PrivacyPolicy.js"));
const ValidationIE = loadable(() => import("views/Validation/ValidationIE.js"));
const ValidationSE = loadable(() => import("views/Validation/ValidationSE.js"));
const ValidationCel = loadable(() =>
  import("views/Validation/ValidationCel.js")
);
const Verificacion2Factor = loadable(() =>
  import("./views/Validation/Verificacion2Factor.js")
);

// const TermsAndConditions = loadable(() => import("views/TermsAndConditions/TermsAndConditions.js"));

// const tagManagerArgs = {
//   gtmId: sources[site].gtm_ID
// }

// TagManager.initialize(tagManagerArgs)

const WrapperComponent = (props) => {
  // current_time: Date.now(),
  // interval: 60000,

  const [currentTime, setCurrentTime] = React.useState(Date.now());
  const [intervalTime, setIntervalTime] = React.useState(60000);
  const [localTime, setLocalTime] = React.useState(Date.now());

  const globalTime = async (checkTime) => {
    let serverTime = Date.now();
    let tries = 0;
    if (Session.loggedIn() && checkTime) {
      const sTime = await setGlobalTime();
      tries = sTime.tries;
      serverTime = sTime.serverTime;
    }

    setCurrentTime(() => {
      return serverTime;
    });

    setLocalTime((localTime) => {
      return Date.now();
    });

    setTimeout(
      globalTime,
      intervalTime,
      Math.abs(serverTime - Date.now()) > 2000 && tries < 5
    );
  };

  useEffect(() => {
    globalTime(true);
  }, []);

  return (
    <TimeContext.Provider
      value={{
        date: currentTime,
        interval: intervalTime,
        localTime: Date.now(),
      }}
    >
      {props.children}
    </TimeContext.Provider>
  );
};

//console.log(currentTime);
ReactDOM.render(
  <WrapperComponent>
    <BrowserRouter>
      <Switch>
        <Route
          path={["/", "/vende/:uri/", "/vende/:uri"]}
          exact
          render={({ match, props }) => (
            <Index
              sources={sources}
              uri={match.params.uri}
              site={site}
              {...props}
            />
          )}
        />
        <Route
          path="/search/:search_term/"
          exact
          render={({ match, props }) => (
            <Index
              search_term={match.params.search_term}
              sources={sources}
              site={site}
              {...props}
            />
          )}
        />
        <Route
          path={[
            "/product/:source/:id(\\d+)/:name/",
            "/product/:source/:id(\\d+)/",
          ]}
          exact
          render={({ match, props }) => (
            <GoodView
              gid={match.params.id}
              source={match.params.source}
              sources={sources}
              site={site}
              {...props}
            />
          )}
        />

        <Route
          path="/verificacion_2factor/:hash/"
          exact={true}
          render={({ match, props }) => (
            <Verificacion2Factor
              site={site}
              sources={sources}
              hash={match.params.hash}
              {...props}
            />
          )}
        />

        <Route
          path={["/login/", "/first_login/"]}
          exact
          render={(props) => <Login site={site} sources={sources} {...props} />}
        />
        {/* <Route
          path={["/first_login/"]}
          exact
          render={(props) => <Login site={site} sources={sources} {...props} />}
        />         */}
        <Route
          path="/user_norm/:norm_error([A-Za-z_]*)"
          exact
          render={({ match, props }) => (
            <Login
              site={site}
              sources={sources}
              norm_error={match.params.norm_error}
              {...props}
            />
          )}
        />

        <Route
          path="/auction_room/"
          exact
          render={(props) => (
            <AuctionRoomView site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/howpay/", "/comopagar/"]}
          exact
          render={(props) => (
            <HowPay site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/payment_media/", "/mediosdepago/"]}
          exact
          render={(props) => (
            <PaymentMedia site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/payment_time/", "/tiempo_pago/"]}
          exact
          render={(props) => (
            <PaymentTime site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/regulation/", "/reglamento/"]}
          exact
          render={(props) => (
            <TermsAndConditions site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/faq/", "/preguntas_frecuentes/"]}
          exact
          render={(props) => <FAQ site={site} sources={sources} {...props} />}
        />
        <Route
          path={["/guides/", "/guia/"]}
          exact
          render={(props) => (
            <Guides site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/contact/", "/contacto/"]}
          exact
          render={(props) => (
            <Contact site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/porcentaje_restante/"]}
          exact
          render={(props) => (
            <RemainingPercentage site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/wanna_auction/", "/quiero_subastar/"]}
          exact
          render={(props) => (
            <WannaAuction site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/quiero_publicitar/"]}
          exact
          render={(props) => (
            <WannaAuction site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/broadcast_help/", "/transmision_instructivo/"]}
          exact
          render={(props) => (
            <BroadcastHelp site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/recover_password/", "/cambiar_contrasena/"]}
          exact
          render={(props) => (
            <RecoverPassword site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/registro/fisica/"]}
          exact
          render={(props) => (
            <RegisterPerson site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/registro/juridica/"]}
          exact
          render={(props) => (
            <RegisterEnterprise site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/api_docs/backup/"]}
          exact
          render={(props) => (
            <Backup site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/api_docs/auth_token/"]}
          exact
          render={(props) => (
            <AuthToken site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/privacy_policy/"]}
          exact
          render={(props) => (
            <PrivacyPolicy site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/registro/validacion/", "/validacionie/"]}
          exact
          render={(props) => (
            <ValidationIE site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/validacion/"]}
          exact
          render={(props) => (
            <ValidationSE site={site} sources={sources} {...props} />
          )}
        />
        <Route
          path={["/validacion_celular/"]}
          exact
          render={(props) => (
            <ValidationCel site={site} sources={sources} {...props} />
          )}
        />

        <Route
          path={["/sms/:code/"]}
          exact
          render={({ match, props }) => (
            <ValidationSE
              code={match.params.code}
              sources={sources}
              site={site}
              {...props}
            />
          )}
        />
        <Route
          path="/landing-page"
          exact
          render={(props) => <Landing {...props} />}
        />
        <Route
          path="/profile-page"
          exact
          render={(props) => <Profile {...props} />}
        />
        <Route
          path="/register-page"
          exact
          render={(props) => <Register {...props} />}
        />
        {/*<Redirect to="/"/>*/}
      </Switch>
    </BrowserRouter>
  </WrapperComponent>,
  document.getElementById("root")
);
